import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import BtnCheck from 'components/BtnCheck/BtnCheck';
import Button from 'components/Button';
import CheckCategory from 'components/CheckCategory';
import UrlCategory from 'components/CheckCategory/UrlCategory';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { go } from 'utils/RouterUtils';

class Filtro extends React.Component {
    static propTypes = {
        onFilterClick: PropTypes.any,
        hideStatus: PropTypes.bool,
    };

    onCategoryChange = (data) => {
        this.props.signalSetField({ value: data.id, path: 'pin.formFilter.category' });
    };

    onTypeClick = (value) => {
        this.props.signalToggle({ value, path: 'pin.formFilter.type' });
    };

    onFiltrarClick = () => {
        if (this.props.location.pathname === '/') {
            go('/mapa');
        }

        this.props.signalFilterPins();

        if (this.props.onFilterClick) {
            this.props.onFilterClick();
        }
    };

    isEqualFormFilter = () => {
        const { formFilter, lastFormFilter } = this.props;

        if (formFilter && lastFormFilter && isEqual(formFilter.toJSON(), lastFormFilter.toJSON())) {
            return true;
        }

        return false;
    };

    render() {
        const { categories } = this.props.config;

        const { formFilter, requestPins, hideStatus } = this.props;

        const { type, category } = formFilter;

        return (
            <React.Fragment>
                <BgPanel>
                    <div className={'title-22'}>Filtrar</div>

                    <Spacer vertical={2} />

                    <DivSmart flex alignCenter spaceBetween>
                        <BtnCheck
                            lightblue
                            label="Pessoas que precisam de ajuda"
                            icon={<img src={require('./assets/pedidos.png')} alt="" />}
                            onClick={this.onTypeClick.bind(this, 'pedir_ajuda')}
                            checked={type.value.indexOf('pedir_ajuda') > -1}
                        />

                        <Spacer horizontal={1} />

                        <BtnCheck
                            pink
                            label="Pessoas que querem ajudar"
                            icon={<img src={require('./assets/ajudar.png')} alt="" />}
                            onClick={this.onTypeClick.bind(this, 'posso_ajudar')}
                            checked={type.value.indexOf('posso_ajudar') > -1}
                        />
                    </DivSmart>

                    <Spacer vertical={3} />

                    <div className={'text-14'}>SELECIONE A CATEGORIA</div>

                    <Spacer vertical={1} />

                    <CheckCategory
                        key={0}
                        data={{ id: 0, name: 'Todas', image: require('./assets/todos.png') }}
                        onChange={this.onCategoryChange}
                        selected={category.value === 0}
                        green
                    ></CheckCategory>

                    {categories
                        .filter((v) => v.type == 'default' || !v.type)
                        .map((v) => {
                            return (
                                <CheckCategory
                                    key={v.id}
                                    data={v}
                                    onChange={this.onCategoryChange}
                                    selected={category.value * 1 === v.id * 1}
                                    green
                                ></CheckCategory>
                            );
                        })}

                    <Spacer vertical={3} />

                    <div className={'text-14'}>PARCEIROS</div>

                    <Spacer vertical={1} />

                    {categories
                        .filter((v) => v.type && v.type == 'sosurl')
                        .map((v) => {
                            return (
                                <CheckCategory
                                    key={v.id}
                                    data={v}
                                    onChange={this.onCategoryChange}
                                    selected={category.value * 1 === v.id * 1}
                                    green
                                ></CheckCategory>
                            );
                        })}

                    {categories
                        .filter((v) => v.type && v.type == 'url')
                        .map((v) => {
                            return (
                                <UrlCategory
                                    key={v.id}
                                    data={v}
                                    green
                                ></UrlCategory>
                            );
                        })}
                </BgPanel>

                <Spacer vertical={4} horizontal={4}>
                    {/* {!hideStatus && <Status />}

                    {!hideStatus && <Spacer vertical={2} />} */}

                    <Button
                        label="Filtrar"
                        onClick={this.onFiltrarClick}
                        loading={requestPins.loading}
                        disabled={this.isEqualFormFilter()}
                    />

                    {hideStatus && <Spacer vertical={2} />}
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect(
    {
        config: state`app.config`,

        requestPins: state`pin.requestPins`,

        lastFormFilter: form(state`pin.lastFormFilter`),

        formFilter: form(state`pin.formFilter`),
        signalFilterPins: signal`pin.filterPins`,

        signalToggle: signal`form.toggleFieldArray`,
        signalSetField: signal`form.setField`,
    },
    withRouter(Filtro)
);
