import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es';
import 'mutation-observer';

//Fix react-snapshot
if (!window.matchMedia) {
    window.matchMedia = () => ({
        addListener: () => {},
        removeListener: () => {},
    });
}

if (!global['requestAnimationFrame']) {
    //IE 9
    global['requestAnimationFrame'] = function(callback) {
        setTimeout(callback, 30);
    };
}

if (!global['WebSocket']) {
    //IE 9
    global['WebSocket'] = function() {};
}

if (process.env.NODE_ENV === 'development') {
    //core-js not fix Object.setPrototypeOf
    (function() {
        Object.setPrototypeOf =
            Object.setPrototypeOf || ({ __proto__: [] } instanceof Array ? setProtoOf : mixinProperties);

        function setProtoOf(obj, proto) {
            obj.__proto__ = proto;
            return obj;
        }

        function mixinProperties(obj, proto) {
            for (const prop in proto) {
                if (!obj.hasOwnProperty(prop)) {
                    obj[prop] = proto[prop];
                }
            }
            return obj;
        }
    })();
}

//Fix vh (viewport) -> ignore header/footer do browser
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
