import classnames from 'classnames';
import { SmartLink } from 'components/ModalAlias/ModalAlias';
import React from 'react';
import { trackEvent } from 'utils/GAUtil';
import styles from './CheckCategory.module.scss';

const CheckCategory = ({ onChange, selected, data, green }) => {
    const { name, image, apoio, apoio_link, apoio_target } = data;

    const onClick = () => {
        onChange(data);
    };

    const onClickApoio = () => {
        trackEvent('precisamos', 'clique_apoio', apoio);
    };

    return (
        <div
            className={classnames(styles.root, {
                [styles.isSelected]: selected,
                [styles.isSelectedGreen]: selected && green,
            })}
            onClick={onClick}
        >
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>

            <div className={styles.label}>
                {name}

                {!!apoio && (
                    <SmartLink class={styles.patrocinado} onClick={onClickApoio} to={apoio_link} target={apoio_target}>
                        <i className="fas fa-info-circle"></i>
                        <span>{apoio}</span>
                    </SmartLink>
                )}
            </div>

            <div className={styles.check}>
                {!selected && <i className="far fa-circle"></i>}

                {selected && !green && <i className="far fa-check-circle"></i>}
                {selected && green && <i className="fas fa-check-circle"></i>}
            </div>
        </div>
    );
};

export default CheckCategory;
