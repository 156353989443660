import 'polyfill';

import { Container } from '@cerebral/react';
import Controller from 'modules/Controller';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/main.scss';

// const basename = document.getElementsByTagName('base')[0].getAttribute('href');

const basename = document.getElementsByTagName('base')[0].getAttribute('href');

ReactDOM.render(
    <React.StrictMode>
        <Container controller={Controller}>
            <BrowserRouter basename={basename}>
                <App />
            </BrowserRouter>
        </Container>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
