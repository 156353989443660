import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import classnames from 'classnames';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import ComponentConnect from 'components/ComponentConnect';
import DivSmart from 'components/DivSmart';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getCity } from 'utils/AppUtils';
import { trackEvent } from 'utils/GAUtil';
import styles from './Step2Endereco.module.scss';

class Step2Endereco extends React.Component {
    state = {
        address: '',
    };

    static defaultProps = {
        title: 'PEDIDO',
    };

    onPlaceSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                let city = getCity(results);

                this.props.signalSetField({ path: 'pin.formAdd.city', value: city });

                return getLatLng(results[0]);
            })
            .then((latLng) => {
                this.props.signalSetLatLng({ latitude: latLng.lat, longitude: latLng.lng });

                this.props.signalSetField({ path: 'pin.formAdd.address', value: address });
                this.props.signalSetField({ path: 'pin.formAdd.lat', value: latLng.lat });
                this.props.signalSetField({ path: 'pin.formAdd.lng', value: latLng.lng });

                if (document.activeElement) {
                    document.activeElement.blur();
                }
            })
            .catch((error) => {
                alert('Houve um erro, digite novamente o endereço.');

                this.props.signalSetField({ path: 'pin.formAdd.city', value: '' });
                this.props.signalSetField({ path: 'pin.formAdd.address', value: '' });
                this.props.signalSetField({ path: 'pin.formAdd.lat', value: '' });
                this.props.signalSetField({ path: 'pin.formAdd.lng', value: '' });
            });
    };

    onNextClick = () => {
        trackEvent('precisamos', this.props.title.toLowerCase() + '_endereco', this.props.formAdd.ref_category);

        this.props.onNext();
    };

    render() {
        const { formAdd, title } = this.props;

        const shouldFetchSuggestions = formAdd.address && formAdd.address.value && formAdd.address.value.length > 4;

        return (
            <React.Fragment>
                <BgPanel>
                    <div className={'subtitle-15'}>{title}</div>

                    <Spacer vertical={4} />

                    <div className={'title-26'}>Informe o seu endereço:</div>

                    <Spacer vertical={2} />

                    <ComponentConnect path="pin.formAdd.address">
                        <PlacesAutocomplete
                            onSelect={this.onPlaceSelect}
                            searchOptions={{ componentRestrictions: { country: ['br'] } }}
                            debounce={1000}
                            shouldFetchSuggestions={shouldFetchSuggestions}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    {/* Fix para ter o touch e o errorMessage no input, se bugar é esse o problema rs */}
                                    <ComponentConnect path="pin.formAdd.address">
                                        <Input {...getInputProps({ placeholder: 'Digite seu endereço' })} />
                                    </ComponentConnect>

                                    <div className={styles.containerAutoComplete}>
                                        {loading && <div className={styles.loading}>Carregando ...</div>}

                                        {suggestions.map((suggestion) => {
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className: classnames(styles.item, {
                                                            [styles.itemSelected]: suggestion.active,
                                                        }),
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </ComponentConnect>

                    <Spacer vertical={2} />

                    <div className="text-16">
                        Se você não quiser informar o seu endereço completo, pode simplesmente indicar o bairro, cidade
                        ou mesmo o CEP. O objetivo é que o seu PIN seja localizado mais facilmente por pessoas que
                        estejam próximas da ajuda.
                    </div>
                </BgPanel>

                <Spacer vertical={4} horizontal={8}>
                    <DivSmart flex row spaceBetween>
                        <ButtonText
                            iconLeft={"<i class='fas fa-chevron-left'></i>"}
                            label={'Retornar'}
                            onClick={this.props.onBack}
                        />

                        <Spacer horizontal={10} />

                        <Button
                            fullwidth
                            label="Avançar"
                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                            spaceBetween
                            onClick={this.onNextClick}
                            disabled={!formAdd.lat.isValid || !formAdd.lng.isValid || !formAdd.address.isValid}
                        />
                    </DivSmart>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect(
    {
        formAdd: form(state`pin.formAdd`),
        signalSetLatLng: signal`pin.setLatLng`,
        signalSetField: signal`form.setField`,
    },
    Step2Endereco
);
