import classnames from 'classnames';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import moment from 'moment';
import React from 'react';
import styles from './CardCategory.module.scss';

const CardCategory = ({ data, pink, onRemoveClick }) => {
    const { id, lat, lng, name, dt_created, description, image } = data;

    const link = `mapa/${id}/${lat}/${lng}`;

    return (
        <div className={classnames(styles.root)}>
            <DivSmart flex alignStart row>
                <a href={link} target="_self">
                    <div className={styles.image}>
                        <img src={image} alt="" />
                    </div>
                </a>
                <Spacer horizontal={0.5} />

                <DivSmart flex flex1 column>
                    <a href={link} target="_self">
                        <div className={classnames(styles.title, { [styles.titlePink]: pink })}>{name}</div>
                        <div className={styles.subtitle}>{moment.unix(dt_created).format('DD/MM/YY')}</div>
                    </a>

                    <div className={styles.text}>{description}</div>
                </DivSmart>

                <div className={styles.btnRemove} onClick={onRemoveClick}>
                    <i className="fas fa-times-circle"></i>
                    <div>Encerrar</div>
                </div>
            </DivSmart>
        </div>
    );
};

export default CardCategory;
