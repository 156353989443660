import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import BlockNotLoggedIn from 'components/BlockNoLoggedIn';
import Loading from 'components/Loading';
import React from 'react';
import { back } from 'utils/RouterUtils';
import Step2Confirmar from './step2Confirmar/Step2Confirmar';

class QueroAjudarPage extends React.Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.signalFetchPin({ id });
    }

    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'pin.requestPin' });
    }

    onBack = () => {
        back();
    };

    onNext = () => {
        const { id } = this.props.match.params;
        this.props.signalPinAccept({ id, type: 'pedir_ajuda' });
    };

    render() {
        const { id } = this.props.match.params;

        const { user, requestPin } = this.props;

        return (
            <React.Fragment>
                {!user && (
                    <BlockNotLoggedIn
                        title="QUERO AJUDAR"
                        text="Para ajudar você precisa estar cadastrado."
                        to={`/quero-ajudar/${id}`}
                    />
                )}

                {requestPin && requestPin.loading && (
                    <BgPanel>
                        <Loading />
                    </BgPanel>
                )}

                {user && requestPin.result && requestPin.result.data && (
                    <React.Fragment>
                        <Step2Confirmar
                            data={requestPin.result.data}
                            subtitle="CONFIRMAÇÃO"
                            title="OFERECER AJUDA"
                            text="Ao clicar no botão abaixo, o usuário que solicitou ajudar receberá os seus dados (nome, e-mail e telefone) para contatá-lo."
                            onBack={this.onBack}
                            onNext={this.onNext}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default connect(
    {
        user: state`auth.user`,
        signalFetchPin: signal`pin.fetchPin`,
        requestPin: state`pin.requestPin`,

        signalPinAccept: signal`pin.pinAccept`,

        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
    },
    QueroAjudarPage
);
