import cls from 'classnames';
import BtnBack from 'pages/infoBase/btnBack/BtnBack';
import React from 'react';
import '../infoBase/InfoBase.scss';
import styles from './ContatoPage.module.scss';

//Utilizado na versão mobile
export default () => {
    return (
        <div className={cls(styles.root, 'InfoBase')}>
            <BtnBack></BtnBack>

            <div className="wrap">
                <div className="subtitle-15">CONTATO</div>

                <div className="title-26">Atendimento & Suporte</div>

                <div className={cls('text-18')}>
                    <div>
                        E-mail:{' '}
                        <a href="mailto:nos@precisamos.com.br" target="_blank">
                            nos@precisamos.com.br
                        </a>
                    </div>

                    <div>
                        Instagram:{' '}
                        <a href="https://www.instagram.com/precisamoscombr/" target="_blank">
                            @precisamoscombr
                        </a>
                    </div>
                </div>
                <div className="title-26">Parcerias & Projetos</div>

                <div className={cls('text-18')}>
                    E-mail:{' '}
                    <a href="mailto:voluntarios@precisamos.com.br" target="_blank">
                        voluntarios@precisamos.com.br
                    </a>
                </div>

                <div className="title-26">Imprensa</div>

                <div className={cls('text-18')}>
                    <div>
                        E-mail:{' '}
                        <a href="mailto:queli@qgcomunica.com.br" target="_blank">
                            queli@qgcomunica.com.br
                        </a>
                    </div>

                    <div>
                        WhatsApp:{' '}
                        <a href="tel:+5551984560846" target="_blank">
                            51 98456-0846
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
