import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import ComponentConnect from 'components/ComponentConnect';
import DivSmart from 'components/DivSmart';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import React from 'react';
import { back } from 'utils/RouterUtils';

class EsqueciPage extends React.Component {
    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'forgot.requestForgot' });
        this.props.signalResetForm({ path: 'forgot.formForgot' });
    }

    onForgotClick = () => {
        const { formForgot } = this.props;

        if (formForgot.isValid) {
            this.props.signalForgot();
        } else {
            this.props.touchForm({ form: 'forgot.formForgot' });
        }
    };

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.onForgotClick();
        }
    };

    onRetornarClick = () => {
        back();
    };

    render() {
        const { requestForgot, formForgot } = this.props;

        return (
            <BgPanel>
                <div className={'subtitle-15'}>RECUPERAR SENHA</div>

                <Spacer vertical={4} />

                <div className={'title-26'}>Digite seu email para receber uma nova senha.</div>

                <Spacer vertical={4} />

                <ComponentConnect path="forgot.formForgot.email">
                    <Input placeholder="E-mail" />
                </ComponentConnect>

                <Spacer vertical={2} />

                <DivSmart flex flex1 />

                <DivSmart flex row spaceBetween>
                    <ButtonText
                        iconLeft={"<i class='fas fa-chevron-left'></i>"}
                        label={'Retornar'}
                        onClick={this.onRetornarClick}
                    />

                    <Spacer horizontal={10} />

                    <Button
                        fullwidth
                        label="Avançar"
                        iconRight={"<i class='fas fa-chevron-right'></i>"}
                        spaceBetween
                        loading={requestForgot.loading}
                        onClick={this.onForgotClick}
                        disabledStyle={!formForgot.isValid}
                    />
                </DivSmart>
            </BgPanel>
        );
    }
}

export default connect(
    {
        formForgot: form(state`forgot.formForgot`),

        requestForgot: state`forgot.requestForgot`,
        signalForgot: signal`forgot.forgot`,

        touchForm: signal`form.touchForm`,
        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
    },
    EsqueciPage
);
