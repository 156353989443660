import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import ComponentConnect from 'components/ComponentConnect';
import DivSmart from 'components/DivSmart';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import React from 'react';
import { back } from 'utils/RouterUtils';

class AlterarSenhaPage extends React.Component {
    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'auth.requestAlterarSenha' });
        this.props.signalResetForm({ path: 'auth.formAlterarSenha' });
    }

    onRetornarPress = () => {
        back();
    };

    onConfirmarPress = () => {
        const { formAlterarSenha } = this.props;

        const { email, code } = this.props.match.params;

        if (formAlterarSenha.isValid) {
            this.props.signalAlterarSenha({ email, code });
        } else {
            this.props.touchForm({ form: 'auth.formAlterarSenha' });
        }
    };

    render() {
        const { formAlterarSenha, requestAlterarSenha } = this.props;

        return (
            <BgPanel>
                <div className={'subtitle-15'}>Alterar Senha</div>

                <Spacer vertical={4} />

                <div className={'title-26'}>Digite sua nova senha.</div>

                <Spacer vertical={4} />

                <div className="label-18">Senha</div>
                <ComponentConnect path="auth.formAlterarSenha.password">
                    <Input placeholder="Senha" type="password" />
                </ComponentConnect>

                <Spacer vertical={3} />

                <div className="label-18">Nova Senha</div>
                <ComponentConnect path="auth.formAlterarSenha.repassword">
                    <Input placeholder="Confirme sua senha" type="password" />
                </ComponentConnect>

                <Spacer vertical={2} />

                <DivSmart flex flex1 />

                <DivSmart flex row spaceBetween>
                    <ButtonText
                        iconLeft={"<i class='fas fa-chevron-left'></i>"}
                        label={'Retornar'}
                        onClick={this.onRetornarPress}
                    />

                    <Spacer horizontal={10} />

                    <Button
                        fullwidth
                        label="Avançar"
                        iconRight={"<i class='fas fa-chevron-right'></i>"}
                        spaceBetween
                        onClick={this.onConfirmarPress}
                        loading={requestAlterarSenha.loading}
                        disabledStyle={!formAlterarSenha.isValid}
                    />
                </DivSmart>
            </BgPanel>
        );
    }
}

export default connect(
    {
        formAlterarSenha: form(state`auth.formAlterarSenha`),

        requestAlterarSenha: state`auth.requestAlterarSenha`,
        signalAlterarSenha: signal`auth.alterarSenha`,

        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
        touchForm: signal`form.touchForm`,
    },
    AlterarSenhaPage
);
