// import { setContext } from 'apollo-link-context';
import Config from 'Config';
import { replace } from '../../utils/RouterUtils';

export default function ({ http, state }) {
    state.set('auth.token', null);
    state.set('auth.user', null);

    localStorage.removeItem(Config.TOKEN);

    http.updateOptions({
        headers: {},
    });

    try {
        window.FB.logout();
    } catch (e) {}

    replace('/');
}
