import classnames from 'classnames';
import Button from 'components/Button';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './CardShare.module.scss';

const CardShare = ({ link, thin, text }) => {
    const [copied, setCopied] = useState(false);

    const shareTwitter = () => {
        window.open(
            'http://twitter.com/share?url=' +
                encodeURIComponent(link + '/square') +
                '&text=' +
                encodeURIComponent(text),
            '',
            'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
        );
    };

    const shareFacebook = () => {
        window.open(
            'http://www.facebook.com/sharer.php?u=' +
                encodeURIComponent(link) +
                '&t=' +
                encodeURIComponent(document.title),
            'popUpWindow',
            'height=300,width=400,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
        );
    };

    const shareLinkedin = () => {
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${link + '/square'}`,
            'sharer',
            'toolbar=0, status=0, width=626, height=436'
        );
    };

    return (
        <div className={classnames(styles.root, { [styles.rootThin]: thin })}>
            {!thin && <div className="title-16 t-center">COMPARTILHAR</div>}

            {!thin && <Spacer vertical={4} />}

            <DivSmart flex row spaceAround>
                <i className={classnames(styles.twitter, styles.icon, 'fab fa-twitter')} onClick={shareTwitter}></i>

                <i className={classnames(styles.facebook, styles.icon, 'fab fa-facebook')} onClick={shareFacebook}></i>

                {/* <i className={classnames(styles.insta, styles.icon, 'fab fa-instagram')} onClick={shareInsta}></i> */}

                <i className={classnames(styles.linkedin, styles.icon, 'fab fa-linkedin')} onClick={shareLinkedin}></i>

                <a className="mobile-only" href={`whatsapp://send?text=${link + '/square'}`}>
                    <i className={classnames(styles.whats, styles.icon, 'fab fa-whatsapp')}></i>
                </a>
            </DivSmart>

            <Spacer vertical={3} />

            <CopyToClipboard
                text={link}
                onCopy={() => {
                    setCopied(true);
                }}
            >
                <Button fullwidth lightblueWhite={!copied} label={copied ? 'Link Copiado' : 'Copiar Link'} />
            </CopyToClipboard>
        </div>
    );
};

CardShare.propTypes = {
    link: PropTypes.string,
};

export default CardShare;
