import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import CardShare from 'components/CardShare';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import Config from 'Config';
import React from 'react';
import { Link } from 'react-router-dom';

const AdicionarPedidoSucesso = ({ user, match }) => {
    const { id } = match.params;

    return (
        <BgPanel>
            <div className={'subtitle-15'}>PEDIDO</div>

            <Spacer vertical={4} />

            <div className={'title-26'}>Pedido publicado com sucesso, {user.name}</div>
            
            <Spacer/>

            <div className={'text-18'}>Aguarde alguns minutos para que o seu pin apareça no mapa</div>

            <Spacer vertical={4} />

            <CardShare
                link={`${Config.URL}share/pin/${id}`}
                text={'Aproximamos quem precisa de ajuda com quem quer ajudar.'}
            />

            <Spacer vertical={4} />

            <DivSmart flex flex1 />

            <Link to={{ pathname: '/pedidos' }}>
                <Button label="Meus Pedidos" />
            </Link>
        </BgPanel>
    );
};

export default connect({ user: state`auth.user` }, AdicionarPedidoSucesso);
