import classnames from 'classnames';
import React from 'react';
import styles from './Loading.module.scss';

const Loading = ({ small }) => {
    return (
        <div className={classnames(styles.root)}>
            <div className={classnames(styles.loading, { [styles.small]: small })} />
        </div>
    );
};

export default Loading;
