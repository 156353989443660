import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ButtonText.module.scss';

const ButtonText = ({ label, iconLeft, iconRight, onClick, className }) => {
    return (
        <div className={classnames(styles.buttonText, className)} onClick={onClick}>
            {!!iconLeft && <div className={styles.iconLeft} dangerouslySetInnerHTML={{ __html: iconLeft }}></div>}

            {label}

            {!!iconRight && <div className={styles.iconRight} dangerouslySetInnerHTML={{ __html: iconRight }}></div>}
        </div>
    );
};

ButtonText.propTypes = {
    label: PropTypes.string,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    onClick: PropTypes.func,
};

export default ButtonText;
