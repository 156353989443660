import React from 'react';
import { withRouter } from 'react-router-dom';
import { trackPageview } from 'utils/GAUtil';

class GoogleAnalytics extends React.Component {
    componentWillUpdate({ location, history }) {
        if (location.pathname === this.props.location.pathname) {
            return;
        }

        if (history.action === 'PUSH' && typeof gtag === 'function') {
            // console.log(location.pathname);
            trackPageview(location.pathname);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(GoogleAnalytics);
