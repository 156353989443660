import { Module } from 'cerebral';
import { debounce, when } from 'cerebral/operators';
import { props } from 'cerebral/tags';
import Config from 'Config';
import cloneDeep from 'lodash/cloneDeep';
import { isDev } from 'utils/AppUtils';
import { trackEvent } from 'utils/GAUtil';
import { go } from 'utils/RouterUtils';
import { APIResponse, get, post } from './services';
import { createFeedback } from './services/APIService';
import { modal } from './services/modal/modal';

const filterPins = [
    debounce(1000),
    {
        continue: [
            setFilterLatLng,
            prepareFilterPins,
            ...get(props`url`, 'pin.requestPins'),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [updateLastFilter],
                false: [],
            },
        ],
        discard: [],
    },
];

export default Module({
    state: {
        requestAdd: APIResponse(),
        formAdd: {
            ref_category: {
                value: null,
                validationRules: ['isRequired'],
            },
            lat: {
                value: null,
                validationRules: ['isRequired'],
            },
            lng: {
                value: null,
                validationRules: ['isRequired'],
            },
            address: {
                value: null,
                validationRules: ['isRequired'],
            },
            city: {
                value: null,
            },
            description: {
                value: isDev()
                    ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nec suscipit eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae'
                    : null,
                validationRules: ['isRequired'],
            },
        },

        requestListPin: APIResponse(),
        requestRemovePin: APIResponse(),

        requestPin: APIResponse(),
        requestPinAccept: APIResponse(),

        requestAcceptPin: APIResponse(),

        requestPins: APIResponse(),

        requestReportPin: APIResponse(),

        defaultCoords: {
            lat: Config.DEFAULT_LAT,
            lng: Config.DEFAULT_LNG,
            zoom: Config.DEFAULT_ZOOM,
            ready: false,
        },

        formFilter: {
            type: {
                value: ['posso_ajudar', 'pedir_ajuda'],
            },
            category: {
                value: 0,
            },
            lat: {
                value: Config.DEFAULT_LAT,
            },
            lng: {
                value: Config.DEFAULT_LNG,
            },
        },
        lastFormFilter: {
            type: {
                value: null,
            },
            category: {
                value: null,
            },
            lat: {
                value: null,
            },
            lng: {
                value: null,
            },
        },
    },
    signals: {
        setLatLng: [
            ({ state, props }) => {
                let { latitude, longitude, ready } = props;

                let ob = { lat: latitude, lng: longitude, zoom: Config.DEFAULT_ZOOM_REQUEST };
                if (ready) {
                    ob.ready = true;
                }
                state.set('pin.defaultCoords', ob);

                state.set('pin.formFilter.lat.value', latitude);
                state.set('pin.formFilter.lng.value', longitude);
            },
        ],

        filterPins: [filterPins],

        fetchPin: [
            prepareFetchPin,
            ...post('api/pin', 'pin.requestPin', props`variables`),
            createFeedback(false, true),
        ],

        fetchAcceptPin: [
            prepareFetchAcceptPin,
            ...post('api/acceptPin', 'pin.requestAcceptPin', props`variables`),
            createFeedback(false, true),
        ],

        pinAccept: [
            preparePinAccept,
            ...post('api/pinAccept', 'pin.requestPinAccept', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [
                    ({ state, props }) => {
                        const request = state.get('pin.requestPinAccept');
                        const id = request.result.data.id;

                        if (props.type === 'pedir_ajuda') {
                            go('/quero-ajudar/sucesso/' + id);
                        } else {
                            go('/aceitar-ajudar/sucesso/' + id);
                        }
                    },
                ],
                false: [],
            },
        ],

        reportPin: [
            prepareReportPin,
            ...post('api/reportPin', 'pin.requestReportPin', props`variables`),
            createFeedback(true, true),
        ],

        addPin: [
            prepareAdd,
            ...post('api/addPin', 'pin.requestAdd', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [
                    ({ state, props }) => {
                        const requestAdd = state.get('pin.requestAdd');
                        const id = requestAdd.result.data.id;

                        if (props.type === 'pedir_ajuda') {
                            go('/pedidos/adicionar-pedido/sucesso/' + id);
                        } else {
                            go('/ajudar/adicionar-ajuda/sucesso/' + id);
                        }
                    },
                ],
                false: [],
            },
        ],

        listPin: [
            prepareListPin,
            ...post('api/listPin', 'pin.requestListPin', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [],
                false: [],
            },
        ],

        removePin: [
            modal(
                {
                    title: props`modalMessage`,
                },
                [
                    { path: 'cancelar', label: 'Cancelar', buttonProps: { grayf3: true } },
                    { path: 'sim', label: 'Sim' },
                ]
            ),
            {
                sim: [
                    prepareRemovePin,
                    ...post('api/removePin', 'pin.requestRemovePin', props`variables`),
                    createFeedback(true, true),
                    when(props`isSuccess`),
                    {
                        true: [...post('api/listPin', 'pin.requestListPin', props`variables`), filterPins],
                        false: [],
                    },
                ],
                cancelar: [],
            },
        ],
    },
});

function setFilterLatLng({ props, state }) {
    if (props.lat && props.lng) {
        state.set('pin.formFilter.lat.value', props.lat);
        state.set('pin.formFilter.lng.value', props.lng);
    }
}

function prepareFilterPins({ forms, props }) {
    let data = forms.toJSON('pin.formFilter');

    let type = data.type && data.type.length > 0 ? data.type.join(',') : 0;

    trackEvent('precisamos', 'filtro_mapa', data.category);

    props.url = `api/filterPins/${type}/${(data.lat * 1).toFixed(5)}/${(data.lng * 1).toFixed(5)}/${data.category}`;
}

function updateLastFilter({ forms, state }) {
    const lastFormFilter = state.get('pin.formFilter');
    state.set('pin.lastFormFilter', cloneDeep(lastFormFilter));
}

function preparePinAccept({ props }) {
    props.variables = {
        id: props.id,
    };
}

function prepareReportPin({ props }) {
    props.variables = {
        id: props.id,
    };
}

function prepareFetchPin({ props }) {
    props.variables = {
        id: props.id,
    };
}

function prepareFetchAcceptPin({ props }) {
    props.variables = {
        id: props.id,
    };
}

function prepareListPin({ props }) {
    props.variables = {
        type: props.type,
    };
}

function prepareAdd({ props, forms }) {
    let data = forms.toJSON('pin.formAdd');
    data.type = props.type;

    props.variables = data;
}

function prepareRemovePin({ props }) {
    props.variables = {
        id: props.id,
        type: props.type,
    };
}
