import classnames from 'classnames';
import React from 'react';
import InfoWindow from '../InfoWindow/InfoWindow';
import styles from './Marker.module.scss';

const Marker = ({ visible, visiblePin, data, onClick, onClose, lat, lng, authUser }) => {
    const { image, image_white, type, point_count } = data;

    const onMarkerClick = function () {
        onClick(data, lat, lng);
    };

    return (
        <React.Fragment>
            <div
                onClick={onMarkerClick}
                className={classnames(styles.marker, {
                    [styles.hidePin]: !visiblePin,
                    [styles.pedidos]: type === 'pedir_ajuda',
                    [styles.ajudar]: type === 'posso_ajudar',
                    // [styles.counter]: !!point_count,
                })}
            >
                {!!point_count && <div>{point_count}</div>}

                {!!image_white && <img src={image_white} className={styles.image} alt="" />}
            </div>

            {visible && <InfoWindow data={data} authUser={authUser} onClose={onClose} />}
        </React.Fragment>
    );
};

export default Marker;
