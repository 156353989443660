import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import React from 'react';

class QueroAjudarSucessoPage extends React.Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.signalFetchAcceptPin({ id });
    }

    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'pin.requestAcceptPin' });
    }

    render() {
        const {
            requestAcceptPin: { result },
        } = this.props;

        return (
            <BgPanel>
                <div className={'subtitle-15'}>OBRIGADO!</div>

                <Spacer vertical={4} />

                <div className={'title-26'}>
                    Obrigado, seus dados foram enviados. Em breve o usuário entrará em contato com você.
                </div>

                <Spacer vertical={2} />

                {!!result && !!result.data && !!result.data.body && (
                    <div dangerouslySetInnerHTML={{ __html: result.data.body }}></div>
                )}

                <DivSmart flex flex1 />
            </BgPanel>
        );
    }
}

export default connect(
    {
        signalFetchAcceptPin: signal`pin.fetchAcceptPin`,
        requestAcceptPin: state`pin.requestAcceptPin`,

        signalResetRequest: signal`form.resetRequest`,
    },
    QueroAjudarSucessoPage
);
