import classnames from 'classnames';
import Button from 'components/Button';
import Filtro from 'components/Filtro';
import Maps from 'components/Maps';
import Spacer from 'components/Spacer';
import Status from 'components/Status';
import React from 'react';
import styles from './MapaPage.module.scss';

//Utilizado na versão mobile
class MapaPage extends React.Component {
    state = {
        filterShow: false,
    };

    onFilterClick = () => {
        this.setState({ filterShow: true });
    };

    onFilterClose = () => {
        this.setState({ filterShow: false });
    };

    render() {
        const { filterShow } = this.state;

        return (
            <div className={classnames('MapaPage', styles.mapa)}>
                {filterShow && (
                    <div className={styles.blockFilter}>
                        <div
                            className={classnames('fas', 'fa-times', styles.btnCloseFilter)}
                            onClick={this.onFilterClose}
                        ></div>

                        <Filtro onFilterClick={this.onFilterClose} hideStatus={true} />
                    </div>
                )}

                <Maps match={this.props.match} />

                <div className={styles.filtro}>
                    <Status />

                    <Spacer vertical={1} />

                    <Button className={styles.btn} label="Filtrar" onClick={this.onFilterClick} />
                </div>
            </div>
        );
    }
}

export default MapaPage;
