import Config from 'Config';
import get from 'lodash/get';

export default function({ props, state }) {
    let token = null;

    const result = get(props, 'response.result');
    if (result && !result.error) {
        if (result.data && result.data.token) {
            token = result.data.token;
        }

        if (token) {
            localStorage.setItem(Config.TOKEN, token);
        }
    }
}
