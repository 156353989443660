import { Module } from 'cerebral';
import { when } from 'cerebral/operators';
import { props } from 'cerebral/tags';
import { isDev } from 'utils/AppUtils';
import { go } from 'utils/RouterUtils';
import { flowLogin } from './AuthModule';
import { APIResponse, post } from './services';
import { createFeedback } from './services/APIService';
import jwt_decode from 'jwt-decode';

export default Module({
    state: {
        requestLogin: APIResponse(),

        formLogin: {
            email: {
                value: isDev() ? 'omegatiger@gmail.com' : '',
                validationRules: ['isRequired', 'isEmail'],
            },
            password: {
                value: isDev() ? 'omegatiger' : '',
                validationRules: ['isRequired'],
            },
        },
    },
    signals: {
        login: [
            removeHeaders,
            prepareLogin,
            ...post('api/login', 'login.requestLogin', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [...flowLogin],
                false: [],
            },
        ],
        loginSocial: [
            removeHeaders,
            prepareLoginSocial,
            ...post('api/loginSocial', 'login.requestLogin', props`variables`),
            createFeedback(false, false),
            when(props`isSuccess`),
            {
                true: [...flowLogin],
                false: [
                    ({ props }) => {
                        go('/cadastro', getSocialData(props.response));
                    },
                ],
            },
        ],
    },
});

function removeHeaders({ http }) {
    http.updateOptions({
        headers: {},
    });
}

function prepareLogin({ state, props, forms }) {
    let data = forms.toJSON('login.formLogin');
    props.variables = data;
}

function prepareLoginSocial({ props, forms }) {
    const { response } = props;
    props.variables = getSocialData(response);
}

function getSocialData(response) {
    console.log('getSocialData', response);

    const data = jwt_decode(response);

    // if (response.googleId) {
    // if (response.aud) {
    return {
        provider: 'google',

        social_id: data.sub,
        name: data.name,
        email: data.email,
        signed_request: response,
    };
    
    // } else {
    //     return {
    //         provider: 'facebook',

    //         social_id: response.id,
    //         name: response.name,
    //         email: response.email,
    //         signed_request: response.signedRequest,
    //     };
    // }
}
