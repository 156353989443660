let _history;

export function setHistory(history) {
    _history = history;
}

export function getHistory() {
    return _history;
}

export function go(path, state = null) {
    _history.push(`${path}`, state);
}

export function back() {
    _history.goBack();
}

export function replace(path, state = null) {
    try {
        _history.replace(`${path}`, state);
    } catch (e) {
        go(path, state);
    }
}
