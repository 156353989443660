import BgPanel from 'components/BgPanel';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import React from 'react';

class AceitarAjudarSucessoPage extends React.Component {
    render() {
        return (
            <BgPanel>
                <div className={'subtitle-15'}>OBRIGADO!</div>

                <Spacer vertical={4} />

                <div className={'title-26'}>
                    Obrigado, seus dados foram enviados. Em breve o usuário entrará em contato com você.
                </div>

                <Spacer vertical={2} />

                <DivSmart flex flex1 />
            </BgPanel>
        );
    }
}

export default AceitarAjudarSucessoPage;
