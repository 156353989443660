import { Module } from 'cerebral';
import { set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';
import { APIResponse, get } from './services';

export default Module({
    state: {
        requestModalApi: APIResponse(),
        modal: null,
    },
    signals: {
        openModal: [set(state`modalApi.modal`, props`alias`)],
        closeModal: [set(state`modalApi.modal`, null)],

        fetch: [prepareLink, ...get(props`url`, 'modalApi.requestModalApi')],
    },
});

function prepareLink({ props }) {
    props.url = `api/modal/${props.alias}`;
}
