import cls from 'classnames';
import Button from 'components/Button';
import ModalSimple from 'components/ModalSimple/ModalSimple';
import Spacer from 'components/Spacer';
import PropTypes from 'prop-types';
import React from 'react';
import './ModalComponent.scss';

export default class ModalComponent extends React.Component {
    static propTypes = {
        buttons: PropTypes.array,
        onButtonClick: PropTypes.func,

        title: PropTypes.string,

        onRequestClose: PropTypes.func,
    };

    static defaultProps = {
        onRequestClose: () => {},
    };

    componentDidMount() {
        if (!this.props.title) {
            this.props.onButtonClick(this.props.buttons[0]);
        }
    }
    renderButtons = (buttons) => {
        return buttons.map((data, i) => {
            return (
                <Button
                    key={i}
                    label={data.label}
                    disabled={data.disabled}
                    {...data.buttonProps}
                    onClick={() => {
                        this.props.onButtonClick(data);
                    }}
                />
            );
        });
    };

    render() {
        const { title, buttons, onRequestClose } = this.props;

        return (
            <ModalSimple className="ModalComponent" visible={true} onClose={onRequestClose}>
                <div className="title" dangerouslySetInnerHTML={{ __html: 'AVISO' }} />

                <Spacer vertical={3} />

                <div className="message" dangerouslySetInnerHTML={{ __html: title }} />

                {this.props.children}

                <Spacer vertical={3} />

                <div className={cls('btns', { 'one-button': buttons.length === 1 })}>{this.renderButtons(buttons)}</div>
            </ModalSimple>
        );
    }
}
