import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import ComponentConnect from 'components/ComponentConnect';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import React from 'react';
import { Link } from 'react-router-dom';

class LoginPage extends React.Component {
    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'login.requestLogin' });
        this.props.signalResetForm({ path: 'login.formLogin' });
    }

    componentDidMount() {
        window.location.hash = '';
    }

    onLoginClick = () => {
        const { to } = this.props.location.state || { to: '' };
        const { formLogin } = this.props;

        if (formLogin.isValid) {
            this.props.signalLogin({ to });
        } else {
            this.props.touchForm({ form: 'login.formLogin' });
        }
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onLoginClick();
        }
    };

    responseFacebook = (response) => {
        console.log('responseFacebook', response);

        if (response.id) {
            this.props.signalLoginSocial({ response });
        } else {
            alert('Erro ao autenticar com o Facebook, tente novamente.');
        }
    };

    responseGoogle = (response) => {
        console.log('responseGoogle', response);

        if (response.credential) {
            this.props.signalLoginSocial({ response: response.credential });
        } else {
            alert(response.error);
        }
    };

    render() {
        const { formLogin, requestLogin } = this.props;

        return (
            <BgPanel>
                <div className={'subtitle-15'}>LOGIN</div>

                <Spacer vertical={4} />

                {/* <ReactFacebookLogin
                    appId="2682061918730814"
                    fields="name,email"
                    callback={this.responseFacebook}
                    autoLoad={false}
                    disableMobileRedirect={true}
                    redirectUri="https://precisamos.com.br/"
                    cookie={true}
                    xfbml={true}
                    isMobile={isMobile()}
                    render={(renderProps) => (
                        <Button
                            onClick={() => {
                                this.props.history.push('/login');
                                console.log(this.props.history);

                                renderProps.onClick();
                            }}
                            iconLeft={"<i class='fab fa-facebook-f' style='font-size:18px; margin-top:-2px'></i>"}
                            label={'Entrar com Facebook'}
                            pink
                            style={{ backgroundColor: '#3b5998' }}
                        />
                    )}
                />

                <Spacer vertical={2} /> */}

                <GoogleOAuthProvider clientId="974995813547-m8dmfkpund7k2gv8s0uom6k68758vhfo.apps.googleusercontent.com">
                    <GoogleLogin
                        clientId="974995813547-m8dmfkpund7k2gv8s0uom6k68758vhfo.apps.googleusercontent.com"
                        onSuccess={this.responseGoogle}
                        onError={this.responseGoogle}
                    ></GoogleLogin>
                </GoogleOAuthProvider>

                {/* <GoogleLogin
                    clientId="974995813547-m8dmfkpund7k2gv8s0uom6k68758vhfo.apps.googleusercontent.com"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    isSignedIn={false}
                    render={(renderProps) => (
                        <Button
                            onClick={renderProps.onClick}
                            iconLeftObject={
                                <img
                                    src={require('./assets/google.png')}
                                    alt=""
                                    style={{ width: '20px', height: 'auto', marginTop: '-2px' }}
                                />
                            }
                            label={'Entrar com Google'}
                            white
                            style={{ border: '1px solid #6b727a' }}
                        />
                    )}
                /> */}

                <Spacer vertical={3} />

                <div className="ou">OU</div>

                <Spacer vertical={3} />

                <div className={'title-26'}>Digite o seu e-mail e senha de acesso.</div>

                <Spacer vertical={3} />

                <ComponentConnect path="login.formLogin.email">
                    <Input placeholder="E-mail" />
                </ComponentConnect>

                <Spacer />

                <ComponentConnect path="login.formLogin.password">
                    <Input placeholder="Senha" type="password" onKeyPress={this.onKeyPress} />
                </ComponentConnect>

                <Spacer vertical={4} />

                <Button
                    label="Entrar"
                    loading={requestLogin.loading}
                    onClick={this.onLoginClick}
                    disabledStyle={!formLogin.isValid}
                />

                <Spacer vertical={4} />

                <Link to="/esqueci">
                    <ButtonText iconLeft={"<i class='fas fa-lock'></i>"} label={'Esqueci minha senha'} />
                </Link>

                <Spacer vertical={2} />

                <Link to="/confirmar-codigo-email">
                    <ButtonText
                        iconLeft={"<i class='fas fa-lock v-hidden'></i>"}
                        label={'Reenviar código de ativação'}
                    />
                </Link>

                <Spacer vertical={2} />

                <Link to="/cadastro">
                    <ButtonText
                        className="color-darkblue"
                        iconLeft={"<i class='fas fa-lock v-hidden'></i>"}
                        label={'Não tenho cadastro'}
                    />
                </Link>
            </BgPanel>
        );
    }
}

export default connect(
    {
        formLogin: form(state`login.formLogin`),

        requestLogin: state`login.requestLogin`,
        signalLogin: signal`login.login`,

        signalLoginSocial: signal`login.loginSocial`,

        touchForm: signal`form.touchForm`,
        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
    },
    LoginPage
);
