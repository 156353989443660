import Button from 'components/Button';
import Spacer from 'components/Spacer';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './TabBar.module.scss';

const TabBar = ({ history }) => {
    const onClick = (page) => {
        history.push(page);
    };

    const { pathname } = history.location;

    const isMapaSelected = pathname.indexOf('mapa') > -1 || pathname === '/';
    const isPedidosSelected = pathname.indexOf('/pedidos') > -1;
    const isAjudarSelected = pathname.indexOf('/ajudar') > -1;

    return (
        <React.Fragment>
            <div className={styles.tabbar}>
                <Button
                    className={styles.btn}
                    grayf3={isMapaSelected}
                    white={!isMapaSelected}
                    fontBlack
                    fullwidth
                    label="MAPA"
                    onClick={onClick.bind(this, '/mapa')}
                />

                <Spacer horizontal={1} />

                <Button
                    className={styles.btn}
                    grayf3={isAjudarSelected}
                    white={!isPedidosSelected}
                    fontBlack
                    fullwidth
                    label="PEDIR AJUDA"
                    onClick={onClick.bind(this, '/pedidos')}
                />

                <Spacer horizontal={1} />

                <Button
                    className={[styles.btn, styles.btnAjudarHover]}
                    grayPink={isAjudarSelected}
                    white={!isAjudarSelected}
                    fontBlack
                    fullwidth
                    label="OFERECER AJUDA"
                    onClick={onClick.bind(this, '/ajudar')}
                />
            </div>

            <div className={styles.emptyTabbar}></div>
        </React.Fragment>
    );
};

TabBar.propTypes = {};

export default withRouter(TabBar);
