import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import Loading from 'components/Loading';
import ModalSliderInfo from 'components/SliderInfo/ModalSliderInfo';
import Config from 'Config';
import GeoJSON from 'geojson';
import GoogleMapReact from 'google-map-react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, Route } from 'react-router-dom';
import Supercluster from 'supercluster';
import { distance, isMobile } from 'utils/AppUtils';
import { trackEvent } from 'utils/GAUtil';
import InfoWindow from './InfoWindow/InfoWindow';
import InputSearch from './InputSearch/InputSearch';
import styles from './Maps.module.scss';
import Marker from './Marker/Marker';
import stylesMap from './stylesMaps';

const superCluster = new Supercluster({
    radius: 120,
    maxZoom: 17,
});

class Maps extends React.Component {
    readyRouter = false;

    state = {
        visible: null,
        overlayZoom: false,
        zoom: Config.DEFAULT_ZOOM,

        me: null,
    };

    static propTypes = {
        match: PropTypes.any,
    };

    componentDidMount() {
        console.log('componentDidMount maps2', this.props.match);
    }

    redirectParamsRouter = () => {
        const {
            match: { params },
        } = this.props;

        //Já consumiu os dados para reposicionar o mapa
        if (this.readyRouter || !params.ref_pin) {
            return false;
        }

        const { lat, lng, ref_pin } = params;

        this.map.setCenter({ lat: lat * 1, lng: lng * 1 });
        this.map.setZoom(19);

        this.readyRouter = true;

        this.setState({ visible: ref_pin });

        return true;
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        //Atualiza quando os dados são alterados (Cluster)
        const nextList = get(nextProps, 'requestPins.data');
        if (!isEqual(get(this.props, 'requestPins.data'), nextList) && this.map) {
            const zoom = this.map.getZoom();
            const bounds = this.map.getBounds();

            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            const arBounds = [southWest.lng(), southWest.lat(), northEast.lng(), northEast.lat()];

            this.updateClusterList(nextList, arBounds, zoom);
        }
    }

    getNewLatLng = (lat, lng, pos) => {
        // let raio = Math.floor(pos / 6) / 10000;
        lat += Math.cos(pos) * 0.00015;
        lng += Math.sin(pos) * 0.00015;
        return { lat, lng };
    };

    updateClusterList = (list, bounds, zoom) => {
        if (!list) {
            return;
        }

        const dynamicPoint = [];

        const newList = [].concat(list).map((v) => {
            const { lat, lng } = v;
            const key = 's' + lat + lng;
            if (!dynamicPoint[key]) {
                dynamicPoint[key] = 0;
            }

            dynamicPoint[key] += 1;

            if (dynamicPoint[key] > 1) {
                const newLatLng = this.getNewLatLng(v.lat * 1, v.lng * 1, dynamicPoint[key]);
                v.lat = newLatLng.lat;
                v.lng = newLatLng.lng;
            }

            return v;
        });

        const geoJson = GeoJSON.parse(newList, { Point: ['lat', 'lng'] });
        superCluster.load(geoJson.features);

        const listCluster = superCluster.getClusters(bounds, zoom);

        this.setState({ listCluster });
    };

    onChange = ({ center, zoom, bounds }) => {
        if (this.state.zoom !== zoom) {
            this.setState({ zoom });
        }

        //Força atualização quando o bounds / zoom / lat/lng são alterados
        const listPins = get(this.props, 'requestPins.data');
        if (listPins) {
            this.updateClusterList(
                get(this.props, 'requestPins.data'),
                [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat],
                zoom
            );
        }

        //Se o movimento não foi maior que X, não precisa fazer um novo request.
        if (distance(center.lat, center.lng, this.prevLat, this.prevLng) < Config.DISTANCE_REQUEST_KM) {
            return;
        }

        //Se o zoom for maior que 9, faz requisição no server para refinar o que tem na tela
        if (zoom >= 9) {
            this.prevLat = center.lat;
            this.prevLng = center.lng;

            this.props.signalFilterPins({ lat: center.lat, lng: center.lng });
        } else {
            //Previne novos requests
            if (this.prevLng === -1 && this.prevLat === -1 && listPins && listPins.length > 0) {
                return;
            }

            this.prevLat = -1;
            this.prevLng = -1;
            this.props.signalFilterPins({ lat: -1, lng: -1 });
        }
    };

    onMarkerMeClick = () => {
        const { lat, lng } = this.state.me;

        this.map.setCenter({ lat, lng });
        this.map.setZoom(Config.DEFAULT_ZOOM_NEAR);

        this.setState({ visible: null });
    };

    onMarkerClick = (data, lat, lng) => {
        if (data.cluster) {
            this.map.setZoom(superCluster.getClusterExpansionZoom(data.cluster_id));
            this.map.panTo({ lat, lng });

            this.setState({ visible: null });

            return;
        }

        let visible = data.id;
        if (visible === this.state.visible) {
            visible = null;
        }

        //Só desktop
        if (!isMobile() && visible) {
            this.map.panTo({ lat, lng });
            setTimeout(() => {
                this.map.panBy(0, 200);
            });
        }

        if (!!visible) {
            trackEvent('precisamos', 'clique_pin_id', data.id);
            trackEvent('precisamos', 'clique_pin_categoria', data.ref_category);
        }

        this.setState({ visible });
    };

    onMarkerClose = () => {
        this.setState({ visible: null });
    };

    onGoogleApiLoaded = ({ map, maps }) => {
        this.map = map;

        const flRedirect = this.redirectParamsRouter();

        //Se não fez redirect via params
        if (!flRedirect) {
            this.setPositionMe(Config.DEFAULT_LAT, Config.DEFAULT_LNG, 8);
        }
    };

    setPositionMe = (lat, lng, zoom) => {
        this.map.setCenter({ lat: lat * 1, lng: lng * 1 });
        this.map.setZoom(zoom);

        this.setState({ me: { lat, lng } });
    };

    onInputChange = ({ lat, lng }) => {
        this.props.signalLatLng({ latitude: lat, longitude: lng });

        this.map.setCenter({ lat, lng });
        this.map.setZoom(Config.DEFAULT_ZOOM_NEAR);

        this.setState({ me: { lat, lng } });
    };

    getInfoWindowData = () => {
        const { visible } = this.state; //id do registro

        const list = get(this.props, 'requestPins.data');

        if (!list) {
            return null;
        }

        return list.filter((v) => v.id === visible)[0];
    };

    onLocationClick = () => {
        navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
                const { latitude, longitude } = coords;
                console.log(latitude, longitude);

                if (latitude && longitude) {
                    this.map.setCenter({ lat: latitude, lng: longitude });
                    this.map.setZoom(Config.DEFAULT_ZOOM_NEAR);
                }
            },
            (err) => {
                console.log(err);
                alert('Não foi possível determinar sua localização. Verifique se o acesso está liberado.');
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            }
        );
    };

    render() {
        const { user, loading } = this.props;

        const { listCluster, visible } = this.state;

        const infoWindowMobile = this.getInfoWindowData();

        // if (isDev()) {
        //     return null;
        // }

        return (
            <div className={styles.maps}>
                {!isMobile() && <Route exact path="/" component={ModalSliderInfo}></Route>}

                {loading && (
                    <div className={styles.loading}>
                        <Loading />
                    </div>
                )}

                <InputSearch onChange={this.onInputChange} />

                {!!visible && !!infoWindowMobile && isMobile() && (
                    <InfoWindow data={infoWindowMobile} onClose={this.onMarkerClose} authUser={user} />
                )}

                <div className={styles.containerBtns}>
                    <Link to="/pedidos/adicionar-pedido">
                        <div className={styles.btnAdd}>
                            <img src={require('./assets/pedidos.png')} alt="" />
                            <span className={styles.btnAddSpanBlue}>Pedir Ajuda</span>
                        </div>
                    </Link>

                    <Link to="/ajudar/adicionar-ajuda">
                        <div className={styles.btnAdd}>
                            <img src={require('./assets/ajudas.png')} alt="" />
                            <span>Oferecer Ajuda</span>
                        </div>
                    </Link>
                </div>

                <div className={styles.btnLocation} onClick={this.onLocationClick}>
                    <i className="fas fa-crosshairs"></i>
                </div>

                <GMaps onChange={this.onChange} onGoogleApiLoaded={this.onGoogleApiLoaded}>
                    {listCluster &&
                        listCluster.map((v) => {
                            return (
                                <Marker
                                    key={!!v.id ? 'counter' + v.id : 'pin' + v.properties.id}
                                    lat={v.geometry.coordinates[1]}
                                    lng={v.geometry.coordinates[0]}
                                    data={v.properties}
                                    onClick={this.onMarkerClick}
                                    onClose={this.onMarkerClose}
                                    visible={this.state.visible === v.properties.id}
                                    visiblePin={true}
                                    authUser={user}
                                />
                            );
                        })}
                </GMaps>
            </div>
        );
    }
}

const GMaps = ({ onChange, onGoogleApiLoaded, children }) => {
    return (
        <GoogleMapReact
            key="maps"
            options={{ styles: stylesMap, minZoom: 4, maxZoom: 19, clickableIcons: false, language: 'pt-BR' }}
            bootstrapURLKeys={{ key: Config.GOOGLE_KEY, language: 'pt-BR' }}
            defaultCenter={{
                lat: Config.DEFAULT_LAT,
                lng: Config.DEFAULT_LNG,
            }}
            defaultZoom={Config.DEFAULT_ZOOM}
            onChange={onChange}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={onGoogleApiLoaded}
        >
            {children}
        </GoogleMapReact>
    );
};

export default connect(
    {
        signalLatLng: signal`pin.setLatLng`,
        signalFilterPins: signal`pin.filterPins`,
        requestPins: state`pin.requestPins.result`,
        loading: state`pin.requestPins.loading`,
        defaultCoords: state`pin.defaultCoords`,
        user: state`auth.user`,
    },
    Maps
);
