let jwtDecode = require('jwt-decode');

export default function({ props, state, http }) {
    const token = props.storedToken;

    if (token) {
        state.set('auth.token', token);
        state.set('auth.user', jwtDecode(token).data);

        http.updateOptions({
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
    }
}
