import cls from 'classnames';
import BtnBack from 'pages/infoBase/btnBack/BtnBack';
import React from 'react';
import '../infoBase/InfoBase.scss';
import styles from './SobrePage.module.scss';

//Utilizado na versão mobile
const SobrePage = () => {
    return (
        <div className={cls(styles.root, 'InfoBase')}>
            <div className={styles.whiteBlock}>
                <BtnBack></BtnBack>

                <div className="wrap">
                    <div className="subtitle-15">MANIFESTO</div>
                    <div className="title-28">Nada deve ser mais importante do que as pessoas</div>
                    <div className={cls('text-18', styles.textWhite)}>
                        <p>
                            O Precisamos.com.br nasceu como uma resposta durante a crise global da Covid-19, conectando
                            pessoas que precisavam de ajuda com aquelas dispostas a oferecer suporte, tudo através de um
                            simples clique.
                        </p>
                        <p>
                            Utilizamos a tecnologia, frequentemente vista como um meio de divisão, para unir e
                            fortalecer a comunidade em tempos de necessidade. Hoje, frente ao desastre que assolou o Rio
                            Grande do Sul, reativamos nossa plataforma com o mesmo espírito de solidariedade e empatia.
                            Acreditamos firmemente que, em momentos de crise, nada deve ser mais importante do que as
                            pessoas.
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.whiteBlock}>
                <div className="wrap">
                    <div className="subtitle-15">CONTEXTO</div>

                    <div className="title-28">Diante dos recentes eventos no Rio Grande do Sul</div>

                    <div className={cls('text-18', styles.textWhite)}>
                        <p>
                            As consequências devastadoras do desastre no Rio Grande do Sul exigem uma resposta
                            coordenada e eficaz para auxiliar aqueles em dificuldade. O isolamento causado por desastres
                            naturais intensifica a necessidade de uma rede de apoio unificada e estruturada.
                        </p>
                        <p>
                            Precisamos.com.br, uma plataforma gratuita e sem fins lucrativos, foi reativada para ser
                            essa conexão vital entre pessoas que precisam de ajuda e aquelas dispostas a ajudar. Ao
                            revitalizar esta iniciativa, buscamos oferecer uma solução segura e confiável que transcenda
                            as soluções temporárias e dispersas que surgem em momentos de crise.
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.whiteBlock}>
                <div className="wrap">
                    <div className="subtitle-15">PRECISAMOS - NA MÍDIA (2020)</div>
                    <div className={cls('text-18', styles.textWhite)}>
                        <a
                            href="https://www.terra.com.br/noticias/tecnologia/inovacao/plataforma-une-pessoas-em-rede-solidaria-durante-a-pandemia,4ae809e0b51eadcdc13194ccf08fa148u8toju90.html"
                            target="_blank"
                        >
                            • 2020, Terra
                        </a>

                        <a
                            href="https://g1.globo.com/rs/rio-grande-do-sul/noticia/2020/05/20/empresa-do-rs-cria-site-para-aproximar-quem-precisa-com-quem-pode-ajudar.ghtml"
                            target="_blank"
                        >
                            • 2020, G1.com
                        </a>

                        <a
                            href="https://gauchazh.clicrbs.com.br/fique-bem/noticia/2020/06/site-aproxima-pessoas-que-precisam-de-ajuda-com-quem-quer-ajudar-ckbb3m8vl00du015nj3atpq2r.html"
                            target="_blank"
                        >
                            • 2020, GZH - Caderno Fique Bem
                        </a>

                        <a
                            href="https://gauchazh.clicrbs.com.br/colunistas/marta-sfredo/noticia/2020/06/fintech-e-plataforma-de-voluntariado-ajudam-pequenos-negocios-a-enfrentar-crise-ckc291uhu00d70162kxyev8na.html"
                            target="_blank"
                        >
                            • 2020, GZH - Coluna Marta Sfredo
                        </a>

                        <a href="https://youtu.be/65WjE4q7zXs?t=1050" target="_blank">
                            • 2020, SBT Rio Grande - 2ª edição
                        </a>

                        <a
                            href="https://www.projetodraft.com/a-base-criou-uma-plataforma-que-faz-o-match-entre-quem-precisa-de-ajuda-e-quem-pode-ajudar/"
                            target="_blank"
                        >
                            • 2020, Projeto Draft
                        </a>

                        <a
                            href="https://coletiva.net/panorama/empresa-de-tecnologia-gaucha-cria-plataforma-de-voluntariado-nacional,356462.jhtml"
                            target="_blank"
                        >
                            • 2020, Coletiva.net
                        </a>

                        <a
                            href="https://www.jornaldocomercio.com/_conteudo/especiais/coronavirus/2020/04/734389-plataforma-conecta-os-voluntarios-a-pessoas-que-precisam-de-apoio.html"
                            target="_blank"
                        >
                            • 2020, Jornal do Comércio
                        </a>

                        <a
                            href="https://empreendedor.com.br/noticia/plataforma-gratuita-une-voluntarios-e-necessitados-pela-crise-da-covid-19/"
                            target="_blank"
                        >
                            • 2020, Empreendedor.com
                        </a>

                        <a
                            href="https://www.covidinnovations.com/home/24042020/brasilian-open-platform-precisamoscombr-connects-people-who-need-help-with-those-who-wish-to-contact-them"
                            target="_blank"
                        >
                            • 2020, COVID innovations
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SobrePage;
