import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getCity } from 'utils/AppUtils';
import Input from '../Input';
import styles from './InputCity.module.scss';

class InputCity extends React.PureComponent {
    static propTypes = {
        placeholder: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = { city: '' };
    }

    handleChange = (city) => {
        this.setState({ city });
    };

    onPlaceSelect = (city) => {
        geocodeByAddress(city)
            .then((results) => {
                city = getCity(results);

                this.props.onChange(city);

                this.setState({ city }, () => {
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                });

                return getLatLng(results[0]);
            })
            .then((latLng) => {
                const { lat, lng } = latLng;

                this.props.onChangeLatLng({ lat, lng });
            })
            .catch((error) => {
                console.log(error);
                alert('Houve um erro, digite novamente o endereço.');
            });
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.city}
                onSelect={this.onPlaceSelect}
                onChange={this.handleChange}
                searchOptions={{ types: ['(regions)'], componentRestrictions: { country: ['br'] } }}
                debounce={1000}
                shouldFetchSuggestions={this.state.city && this.state.city.length > 3 ? true : false}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={styles.root}>
                        <Input {...getInputProps({ placeholder: this.props.placeholder, error: this.props.error })} />

                        <div className={styles.containerAutoComplete}>
                            {loading && <div className={styles.loading}>Carregando ...</div>}

                            {suggestions.map((suggestion) => {
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className: classnames(styles.item, {
                                                [styles.itemSelected]: suggestion.active,
                                            }),
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default InputCity;
