import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import Spacer from 'components/Spacer';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const BlockNotLoggedIn = ({ title, text, to }) => {
    return (
        <BgPanel>
            <div className={'subtitle-15'}>{title}</div>

            <Spacer vertical={4} />

            <div className={'title-26'}>{text}</div>

            <Spacer vertical={6} />

            <Link to={{ pathname: '/login', state: { to } }}>
                <Button lightblueWhite label="Já tenho cadastro" />
            </Link>

            <Spacer vertical={2} />

            <Link to={{ pathname: '/cadastro', state: { to } }}>
                <Button label="Não tenho cadastro" />
            </Link>
        </BgPanel>
    );
};

BlockNotLoggedIn.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

export default BlockNotLoggedIn;
