import { Module } from 'cerebral';
import filter from 'lodash/filter';

let cursor = 0;

const promises = {};

export function modalFeedback(id, path = null) {
    let p = promises[id];

    let list = p.ctx.state.get('modal.list');

    list = filter(list, (item) => item.id !== id);

    p.ctx.state.set('modal.list', list);

    if (path) {
        p.resolve(p.ctx.path[path]());
    } else {
        p.resolve();
    }
    delete promises[id];
}

export function showLoading(id) {
    return function (ctx) {
        let loading = ctx.state.get('modal.loading');
        loading = { ...loading };
        loading[id] = true;
        ctx.state.set('modal.loading', loading);
    };
}

export function hideLoading(id) {
    return function (ctx) {
        let loading = ctx.state.get('modal.loading');
        loading = { ...loading };
        if (loading[id]) {
            delete loading[id];
            ctx.state.set('modal.loading', loading);
        }
    };
}
export function hideAllLoading() {
    return function (ctx) {
        ctx.state.set('modal.loading', {});
    };
}

export function modal(opts, paths = [], uid = null) {
    if (typeof opts == 'string') {
        opts = { title: opts };
    }
    // Modal ID
    return function (ctx) {
        const title = ctx.resolve.value(
            typeof opts == 'string'
                ? opts
                : typeof opts.title == 'string' || typeof opts.title == 'object'
                ? opts.title
                : opts.title()
        );

        const id = uid || ++cursor;

        ctx.state.push('modal.list', {
            id: id,
            title: title,
            paths: paths,
        });
        // se tem paths para aguardar..

        const promise = new Promise((resolve, reject) => {
            promises[id] = { resolve, reject, ctx };

            if (!paths || paths.length === 0) {
                setTimeout(
                    () => {
                        modalFeedback(id);
                    },
                    opts.time ? opts.time * 1 : 4000
                );
            }
        });

        return promise;
    };
}

export default Module({
    state: {
        list: [],
        loading: {},
    },
});
