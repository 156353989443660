import cls from 'classnames';
import BtnBack from 'pages/infoBase/btnBack/BtnBack';
import React from 'react';
import '../infoBase/InfoBase.scss';
import styles from './ParceirosPage.module.scss';

//Utilizado na versão mobile
const ParceirosPage = () => {
    return (
        <div className={cls(styles.root, 'InfoBase')}>
            <BtnBack></BtnBack>
            <div className="wrap">
                <div className="subtitle-15">VOLUNTÁRIOS</div>

                <div className={cls('text-18')}>
                    A Plataforma é uma iniciativa da empresa{' '}
                    <a href="https://base.digital" target="_blank">
                        BASE Digital
                    </a>{' '}
                    e atualmente conta com os seguintes parceiros voluntários para lançamento e divulgação.
                </div>

                <div className={cls('subtitle-15', styles.titleIniciativa)}>INICIATIVA</div>

                <a className={styles.logoBase} href="https://base.digital" target="_blank">
                    <img src={require('./base.png')} alt=""></img>
                </a>

                <div className={cls('subtitle-15', styles.titleIniciativa)}>APOIO</div>

                <div className={styles.logos}>
                    <a href="https://qgcomunica.com.br/" target="_blank" rel="noopener noreferrer">
                        <img src={require('./qg.png')} alt=""></img>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ParceirosPage;
