import Modal from 'components/Modal';
import React from 'react';
import SliderInfo from './SliderInfo';

class ModalSliderInfo extends React.Component {
    render() {
        if (localStorage.getItem('precisamos_slider_info')) {
            return null;
        }

        return (
            <Modal visible={true}>
                <SliderInfo />
            </Modal>
        );
    }
}

export default ModalSliderInfo;
