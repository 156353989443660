import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import classnames from 'classnames';
import GoogleAnalytics from 'components/GoogleAnalytics/GoogleAnalytics';
import Header from 'components/Header';
import Menu from 'components/Menu';
import ModalAlias from 'components/ModalAlias/ModalAlias';
import ScrollToTop from 'components/ScrollToTop';
import ModalContainer from 'modules/services/modal/ModalContainer';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import Media from 'react-media';
import { Route, withRouter } from 'react-router-dom';
import Router from 'Router';
import { setHistory } from './utils/RouterUtils';

const queries = {
    desktop: '(min-width: 1024px)',
    mobile: '(max-width: 1023px)',
    //tablet: '(min-width: 600px) and (max-width: 1199px)',
};

class App extends React.Component {
    componentDidMount() {
        this.props.signalInitApp();
    }

    initRouter = () => {
        setHistory(this.props.history);
    };

    render() {
        const { ready, menu, modalApi } = this.props;

        return (
            <Route name="App" ref={this.initRouter}>
                <GoogleAnalytics />

                <ScrollToTop />

                <Media
                    defaultMatches={{
                        desktop: true,
                    }}
                    queries={queries}
                    render={(matches) => {
                        return (
                            <div
                                className={classnames('App', {
                                    'is-mobile': matches.mobile,
                                    'is-desktop': matches.desktop,
                                    'with-hover': matches.desktop,
                                })}
                            >
                                <div id="container-modal" />

                                <Header />

                                <Menu />

                                {!!ready && <Route component={Router} />}

                                {/* Tratamendo especifico para o TABBAR */}

                                {!!modalApi && <ModalAlias alias={modalApi} />}

                                <ModalContainer />
                            </div>
                        );
                    }}
                ></Media>
            </Route>
        );
    }
}

export default hot(
    connect(
        {
            ready: state`app.ready`,
            signalInitApp: signal`app.initApp`,
            modalApi: state`modalApi.modal`,
        },
        withRouter(App)
    )
);
