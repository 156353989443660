import Maps from 'components/Maps';
import TabBar from 'components/TabBar';
import AceitarAjudarPage from 'pages/aceitarAjudar/AceitarAjudarPage';
import AceitarAjudarSucessoPage from 'pages/aceitarAjudarSucesso/AceitarAjudarSucessoPage';
import AdicionarAjudaPage from 'pages/adicionarAjuda/AdicionarAjudaPage';
import AdicionarAjudaSucessoPage from 'pages/adicionarAjudaSucesso/AdicionarAjudaSucessoPage';
import AdicionarPedidoPage from 'pages/adicionarPedido/AdicionarPedidoPage';
import AdicionarPedidoSucesso from 'pages/adicionarPedidoSucesso/AdicionarPedidoSucesso';
import AjudarPage from 'pages/ajudar/AjudarPage';
import AlterarSenhaPage from 'pages/alterarSenha/AlterarSenhaPage';
import CadastroPage from 'pages/cadastro/CadastroPage';
import ConfirmarCodigoPage from 'pages/confirmarCodigo/ConfirmarCodigoPage';
import ConfirmarCodigoEmailPage from 'pages/confirmarCodigoEmail/ConfirmarCodigoEmailPage';
import ContatoPage from 'pages/contato/ContatoPage';
import EsqueciPage from 'pages/esqueci/EsqueciPage';
import EsqueciAlterarSenhaPage from 'pages/esqueciAlterarSenha/EsqueciAlterarSenhaPage';
import FiltroPage from 'pages/filtro/FiltroPage';
import HomePage from 'pages/home/HomePage';
import LoginPage from 'pages/login/LoginPage';
import MapaPage from 'pages/mapa/MapaPage';
import ParceirosPage from 'pages/parceiros/ParceirosPage';
import PedidosPage from 'pages/pedidos/PedidosPage';
import PrivacidadePage from 'pages/privacidade/PrivacidadePage';
import QueroAjudarPage from 'pages/queroAjudar/QueroAjudarPage';
import QueroAjudarSucessoPage from 'pages/queroAjudarSucesso/QueroAjudarSucessoPage';
import SobrePage from 'pages/sobre/SobrePage';
import TermosPage from 'pages/termos/TermosPage';
import React from 'react';
import Media from 'react-media';
import { Route, Switch, withRouter } from 'react-router-dom';
import styles from './Router.module.scss';

// const AboutPage = Loadable({
//     loader: () => import('./pages/about/AboutPage'),
//     loading: Loading,
// });

const queries = {
    desktop: '(min-width: 1024px)',
    mobile: '(max-width: 1023px)',
};

const WrapRouter = (props) => (
    <Media
        queries={queries}
        render={(matches) => {
            return (
                <div className={styles.root}>
                    <div className={styles.blockLeft}>
                        <Switch>
                            {matches.mobile && (
                                <React.Fragment>
                                    <Route exact path="/" component={null}></Route>
                                    <Route exact path="/sobre" component={null}></Route>
                                    <Route exact path="/parceiros" component={null}></Route>
                                    <Route exact path="/termos-de-uso" component={null}></Route>
                                    <Route exact path="/privacidade" component={null}></Route>
                                    <Route exact path="/contato" component={null}></Route>
                                    <Route path="/" component={TabBar}></Route>
                                </React.Fragment>
                            )}

                            <Route path="/" component={TabBar}></Route>
                        </Switch>

                        {/* Páginas */}
                        <Switch>
                            <Route path="/quero-ajudar/sucesso/:id" component={QueroAjudarSucessoPage}></Route>
                            <Route path="/quero-ajudar/:id" component={QueroAjudarPage}></Route>

                            <Route path="/aceitar-ajudar/sucesso/:id" component={AceitarAjudarSucessoPage}></Route>
                            <Route path="/aceitar-ajudar/:id" component={AceitarAjudarPage}></Route>

                            <Route
                                path="/pedidos/adicionar-pedido/sucesso/:id"
                                component={AdicionarPedidoSucesso}
                            ></Route>
                            <Route
                                path="/pedidos/adicionar-pedido/:categoria_id"
                                component={AdicionarPedidoPage}
                            ></Route>
                            <Route path="/pedidos/adicionar-pedido" component={AdicionarPedidoPage}></Route>
                            <Route path="/pedidos" component={PedidosPage}></Route>

                            <Route
                                path="/ajudar/adicionar-ajuda/sucesso/:id"
                                component={AdicionarAjudaSucessoPage}
                            ></Route>
                            <Route path="/ajudar/adicionar-ajuda/:categoria_id" component={AdicionarAjudaPage}></Route>
                            <Route path="/ajudar/adicionar-ajuda" component={AdicionarAjudaPage}></Route>
                            <Route path="/ajudar" component={AjudarPage}></Route>

                            <Route path="/login" component={LoginPage}></Route>

                            <Route path="/esqueci" component={EsqueciPage}></Route>
                            {/* <Route path="/esqueci-email" component={EsqueciEmailPage}></Route> */}
                            <Route path="/cadastro" component={CadastroPage}></Route>
                            <Route path="/confirmar-codigo/:code?/:email?" component={ConfirmarCodigoPage}></Route>
                            <Route path="/confirmar-codigo-email" component={ConfirmarCodigoEmailPage}></Route>

                            <Route path="/alterar-minha-senha" component={AlterarSenhaPage}></Route>

                            <Route path="/alterar-senha/:code?/:email?" component={EsqueciAlterarSenhaPage}></Route>

                            {matches.mobile && <Route exact path="/" component={HomePage}></Route>}
                            {/* {matches.mobile && <Route path="/mapa/:ref_pin?/:lat?/:lng?" component={MapaPage}></Route>} */}
                            {matches.mobile && <Route path="/sobre" component={SobrePage}></Route>}
                            {matches.mobile && <Route path="/parceiros" component={ParceirosPage}></Route>}
                            {matches.mobile && <Route path="/contato" component={ContatoPage}></Route>}
                            {matches.mobile && <Route path="/termos-de-uso" component={TermosPage}></Route>}
                            {matches.mobile && <Route path="/privacidade" component={PrivacidadePage}></Route>}

                            {matches.desktop && <Route path="/mapa" component={FiltroPage}></Route>}
                            {matches.desktop && <Route path="/" component={FiltroPage}></Route>}
                        </Switch>
                    </div>

                    {matches.mobile && <CheckRoute />}
                    {matches.mobile && (
                        <Switch>
                            <Route path="/mapa/:ref_pin?/:lat?/:lng?" component={MapaPage}></Route>
                            <Route path="/" component={MapaPage}></Route>
                        </Switch>
                    )}

                    {matches.desktop && (
                        <div className={styles.blockRight}>
                            <Switch>
                                {/* <Route path="/pedidos/adicionar-pedido" component={MapsAdd}></Route> */}
                                {/* <Route path="/ajudar/adicionar-ajuda" component={MapsAdd}></Route> */}
                                <Route path="/mapa/:ref_pin?/:lat?/:lng?" component={Maps}></Route>
                                <Route path="/" component={Maps}></Route>
                            </Switch>

                            <Switch>
                                <Route path="/sobre" component={SobrePage}></Route>
                                <Route path="/parceiros" component={ParceirosPage}></Route>
                                <Route path="/contato" component={ContatoPage}></Route>
                                <Route path="/privacidade" component={PrivacidadePage}></Route>
                                <Route path="/termos-de-uso" component={TermosPage}></Route>
                            </Switch>
                        </div>
                    )}
                </div>
            );
        }}
    ></Media>
);

class CheckRouteWrap extends React.Component {
    componentDidMount() {
        this.checkMaps(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.checkMaps(nextProps);
    }

    checkMaps = (props) => {
        if (props.location.pathname.indexOf('mapa') > -1) {
            document.body.classList.remove('is-map-hidden');
        } else {
            document.body.classList.add('is-map-hidden');
        }
    };

    render() {
        return null;
    }
}
const CheckRoute = withRouter(CheckRouteWrap);

export default WrapRouter;
