import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import CheckCategory from 'components/CheckCategory';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import React from 'react';
import { trackEvent } from 'utils/GAUtil';

class Step1Categoria extends React.Component {
    static defaultProps = {
        title: 'PEDIDO',
        text: 'Escolha uma categoria do que você precisa:',
        type: 'pedir-ajuda',
    };

    onCategoryChange = (data) => {
        this.props.signalSetField({ path: 'pin.formAdd.ref_category', value: data.id });
    };

    onNextClick = () => {
        trackEvent('precisamos', this.props.title.toLowerCase() + '_categoria', this.props.formAdd.ref_category);

        this.props.onNext();
    };

    render() {
        const { formAdd, title, text, type } = this.props;

        const { categories } = this.props.config;

        return (
            <React.Fragment>
                <BgPanel>
                    <div className={'subtitle-15'}>{title}</div>

                    <Spacer vertical={4} />

                    <div className={'title-26'}>{text}</div>

                    <Spacer vertical={2} />

                    {categories
                        .filter((v) => v.type == 'default')
                        .map((v) => {
                            if (
                                (type === 'oferecer-ajuda' && v.fl_oferecer_ajuda * 1 === 1) ||
                                (type === 'pedir-ajuda' && v.fl_pedir_ajuda * 1 === 1)
                            ) {
                                return (
                                    <CheckCategory
                                        key={v.id}
                                        data={v}
                                        onChange={this.onCategoryChange}
                                        selected={v.id * 1 === formAdd.ref_category.value * 1}
                                    ></CheckCategory>
                                );
                            }
                        })}
                </BgPanel>

                <Spacer vertical={4} horizontal={8}>
                    <DivSmart flex row spaceBetween>
                        <ButtonText
                            iconLeft={"<i class='fas fa-chevron-left'></i>"}
                            label={'Retornar'}
                            onClick={this.props.onBack}
                        />

                        <Spacer horizontal={10} />

                        <Button
                            fullwidth
                            label="Avançar"
                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                            spaceBetween
                            onClick={this.onNextClick}
                            disabled={!formAdd.ref_category.isValid}
                        />
                    </DivSmart>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect(
    { formAdd: form(state`pin.formAdd`), config: state`app.config`, signalSetField: signal`form.setField` },
    Step1Categoria
);
