import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import Button from 'components/Button';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import Status from 'components/Status';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'utils/AppUtils';
import { go } from 'utils/RouterUtils';
import styles from './Header.module.scss';

class Header extends React.Component {
    state = {
        openMenu: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname && this.state.openMenu) {
            this.setState({ openMenu: false });
        }
    }

    onLoginClick = () => {
        go('/login');
    };

    onToggleMenu = () => {
        this.setState({ openMenu: !this.state.openMenu });
    };

    onChangePasswordClick = () => {
        this.setState({ openMenu: false });
        go('/alterar-minha-senha');
    };

    onDeleteClick = () => {
        this.setState({ openMenu: false });
        this.props.signalDeleteUser();
    };

    onLogoutClick = () => {
        this.setState({ openMenu: false });
        this.props.signalLogout();
    };

    closeMenu = () => {
        this.setState({ openMenu: false });
    };

    onMenuClick = () => {
        this.setState({ openMenu: false });
        this.props.signalOpenMenu();
    };

    render() {
        const { user } = this.props;

        return (
            <React.Fragment>
                <div className={styles.header}>
                    <DivSmart flex flex1 row alignCenter>
                        <div className={styles.btnMenu} onClick={this.onMenuClick}>
                            <img src={require('./assets/btn-menu.png')} alt="" />
                        </div>

                        <Spacer horizontal={2} />

                        <Link to="/mapa">
                            <div className={styles.logo}>
                                <img src={require('./assets/logo-nova.png')} alt="" />
                            </div>
                        </Link>
                    </DivSmart>

                    <div className="desktop-only">
                        <Status />
                    </div>

                    <div className="desktop-only">
                        <Spacer horizontal={2} />
                    </div>

                    {!user && (
                        <Button btnMedium lightblue30 fontBold label={'Entrar'} onClick={this.onLoginClick}></Button>
                    )}

                    {!!user && (
                        <Button
                            className={styles.name}
                            btnMedium
                            fontBold
                            label={
                                isMobile()
                                    ? user.name
                                        ? 'Oi ' + user.name[0] + '.'
                                        : ''
                                    : user.name
                                    ? user.name.split(' ')[0]
                                    : ''
                            }
                            iconLeft="<i class='far fa-user'></i>"
                            iconRight="<i class='fas fa-chevron-down'></i>"
                            onClick={this.onToggleMenu}
                        ></Button>
                    )}

                    {this.state.openMenu && (
                        <div className={styles.menuProfile}>
                            <Button
                                textLeft
                                btnMedium
                                white
                                label="Alterar senha"
                                onClick={this.onChangePasswordClick}
                            />

                            <Spacer />

                            <Button textLeft btnMedium white label="Excluir Cadastro" onClick={this.onDeleteClick} />

                            <Spacer />

                            <Button textLeft btnMedium white label="Sair" onClick={this.onLogoutClick} />
                        </div>
                    )}
                </div>

                <div className={styles.emptyHeader}></div>
            </React.Fragment>
        );
    }
}

export default connect(
    {
        user: state`auth.user`,
        signalDeleteUser: signal`auth.deleteUser`,
        signalLogout: signal`auth.logout`,
        signalOpenMenu: signal`app.openMenu`,
    },
    withRouter(Header)
);
