import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './BtnCheck.module.scss';

const BtnCheck = ({ label, icon, pink, lightblue, checked, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={classnames(styles.btnCheck, {
                [styles.checkedPink]: checked && pink,
                [styles.checkedLightblue]: checked && lightblue,
            })}
        >
            <div className={classnames(styles.label)}>{label}</div>

            {!!icon && <div className={styles.icon}>{icon}</div>}
        </div>
    );
};

BtnCheck.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    onClick: PropTypes.func,
};

export default BtnCheck;
