import classnames from 'classnames';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Button.module.scss';

const Button = ({
    comp = "div",
    className,
    label,
    fullwidth,
    btnMedium,

    disabled,
    disabledStyle,
    loading,

    textLeft,

    white,
    grayPink,
    pink,
    grayf3,
    lightblue,
    lightblue30,
    lightblueWhite,
    red,

    colorChecked,
    colorUnchecked,

    fontBlack,
    fontBold,

    spaceBetween,

    iconLeft,
    iconRight,

    iconLeftObject,

    ...otherProps
}) => {
    const Comp = comp;

    return (
        <Comp
            {...otherProps}
            className={classnames(className, styles.button, {
                [styles.btnMedium]: btnMedium,

                [styles.fullwidth]: fullwidth,
                [styles.spaceBetween]: spaceBetween,

                [styles.fontBold]: fontBold,
                [styles.fontBlack]: fontBlack,

                [styles.disabled]: disabled,
                [styles.disabledStyle]: disabledStyle,
                [styles.loading]: loading,

                [styles.textLeft]: textLeft,

                [styles['color-white']]: white,
                [styles['color-graypink']]: grayPink,
                [styles['color-pink']]: pink,
                [styles['color-lightblue']]: lightblue,
                [styles['color-lightblue-white']]: lightblueWhite,
                [styles['color-lightblue30']]: lightblue30,
                [styles['color-grayf3']]: grayf3,
                [styles['color-red']]: red,
                [styles['color-checked']]: colorChecked,
                [styles['color-unchecked']]: colorUnchecked,
            })}
        >
            {!!iconLeft && <div className={styles.iconLeft} dangerouslySetInnerHTML={{ __html: iconLeft }}></div>}

            {!!iconLeftObject && <div className={styles.iconLeft}>{iconLeftObject}</div>}

            <div className={classnames('label', styles.label)}>{label}</div>

            {!!loading && (
                <div>
                    <Loading small />
                </div>
            )}

            {!!iconRight && <div className={styles.iconRight} dangerouslySetInnerHTML={{ __html: iconRight }}></div>}
        </Comp>
    );
};

Button.propTypes = {
    comp: PropTypes.string,
    label: PropTypes.string,
    fullwidth: PropTypes.bool,

    btnMedium: PropTypes.bool,

    fontBold: PropTypes.bool,
    fontBlack: PropTypes.bool,

    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    spaceBetween: PropTypes.bool,

    white: PropTypes.bool,
    grayf3: PropTypes.bool,
    lightblueWhite: PropTypes.bool,
    lightblue: PropTypes.bool,
    lightblue30: PropTypes.bool,
    checked: PropTypes.bool,
};

export default Button;
