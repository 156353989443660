import classnames from 'classnames';
import React from 'react';
import styles from './CheckCategory.module.scss';

const UrlCategory = ({ data, green }) => {
    const { name, image, url_link } = data;

    return (
        <a href={url_link} className={classnames(styles.root, styles.rootUrl)} target="_blank" alt={name}>
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>

            <div className={styles.label}>{name}</div>
        </a>
    );
};

export default UrlCategory;
