import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import classnames from 'classnames';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'utils/AppUtils';
import styles from './Menu.module.scss';

const BtnMenu = ({ to, label, image, onClick }) => {
    return (
        <Link to={to} className={styles.BtnMenu} onClick={onClick}>
            <div className={styles.BtnMenuImage}>
                <img src={image} alt="" />
            </div>

            <div className={styles.BtnMenuLabel}>{label}</div>
        </Link>
    );
};
const Menu = ({ signalCloseMenu, menu }) => {
    const onBtnMenuClick = (to) => {
        if (isMobile()) {
            signalCloseMenu();
        }
    };

    const onBtnMapaClick = (to) => {
        signalCloseMenu();
    };

    return (
        <div
            className={classnames(
                {
                    'is-open-menu': menu,
                },
                styles.root
            )}
        >
            <div className={styles.content}>
                <DivSmart flex row alignCenter>
                    <Link to="/mapa" onClick={signalCloseMenu}>
                        <div className={styles.btnClose}>
                            <img src={require('./assets/close.png')} alt="" />
                        </div>
                    </Link>

                    <Spacer horizontal={4} />

                    <Link to="/mapa" onClick={signalCloseMenu}>
                        <div className={styles.logo}>
                            <img src={require('./assets/logo.png')} alt="" />
                        </div>
                    </Link>
                </DivSmart>

                <Spacer vertical={4} />

                <BtnMenu to="/mapa" label="Mapa" image={require('./assets/mapa.png')} onClick={onBtnMapaClick} />

                <BtnMenu to="/sobre" label="Sobre" image={require('./assets/sobre.png')} onClick={onBtnMenuClick} />

                <BtnMenu
                    to="/parceiros"
                    label="Parceiros"
                    image={require('./assets/parceiros.png')}
                    onClick={onBtnMenuClick}
                />

                <BtnMenu
                    to="/termos-de-uso"
                    label="Termos de Uso"
                    image={require('./assets/termos-de-uso.png')}
                    onClick={onBtnMenuClick}
                />

                <BtnMenu
                    to="/privacidade"
                    label="Privacidade"
                    image={require('./assets/privacidade.png')}
                    onClick={onBtnMenuClick}
                />

                <BtnMenu
                    to="/contato"
                    label="Contato"
                    image={require('./assets/contato.png')}
                    onClick={onBtnMenuClick}
                />

                <Spacer vertical={4} />
            </div>
        </div>
    );
};

Menu.propTypes = {};

export default connect({ menu: state`app.menu`, signalCloseMenu: signal`app.closeMenu` }, Menu);
