import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import CardConfirm from 'components/CardConfirm';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import moment from 'moment';
import React from 'react';
import { trackEvent } from 'utils/GAUtil';

class Step4Revisao extends React.Component {
    static defaultProps = {
        title: 'PEDIDO',
        text: 'Revise o seu pedido antes de publicar:',
    };

    onNextClick = () => {
        trackEvent('precisamos', this.props.title.toLowerCase() + '_publicar', this.props.formAdd.ref_category);

        this.props.onNext();
    };

    render() {
        const { formAdd, requestAdd, title, text } = this.props;

        return (
            <React.Fragment>
                <BgPanel>
                    <div className={'subtitle-15'}>{title}</div>

                    <Spacer vertical={4} />

                    <div className={'title-26'}>{text}</div>

                    <Spacer vertical={2} />

                    <CardConfirm
                        id={formAdd.ref_category.value}
                        text={formAdd.description.value}
                        date={moment().format('DD/MM/YY')}
                    />
                </BgPanel>

                <Spacer vertical={4} horizontal={8}>
                    <DivSmart flex row spaceBetween>
                        <ButtonText
                            iconLeft={"<i class='fas fa-chevron-left'></i>"}
                            label={'Retornar'}
                            onClick={this.props.onBack}
                        />

                        <Spacer horizontal={10} />

                        <Button
                            fullwidth
                            label="Publicar"
                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                            spaceBetween
                            loading={requestAdd.loading}
                            onClick={this.onNextClick}
                            disabled={!formAdd.isValid}
                        />
                    </DivSmart>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect({ formAdd: form(state`pin.formAdd`), requestAdd: state`pin.requestAdd` }, Step4Revisao);
