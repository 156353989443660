// const API = ''; //document.getElementsByTagName('meta')['api'].getAttribute('content');
const URL = document.getElementsByTagName('meta')['url'].getAttribute('content');

let Config = {
    TOKEN: 'precisamos_v1',
    CEREBRAL_DEBUGGER: 'localhost:8585',
    ENDPOINT: '',
    GOOGLE_KEY: 'AIzaSyCEwg8KG1fFDbdNjewAIKuWFzao-UKcX5w',
    URL: process.env.NODE_ENV === 'development' ? 'http://www.auxteam.com.br/precisamos2/' : URL, //NAO ESQUECER DE POR A PORRA DA BARRA NO FINAL
    DEFAULT_LAT: -30.033056, // -15.834535, //-29.1696008,
    DEFAULT_LNG: -51.23, //-52.514648, //-51.169393,
    DEFAULT_ZOOM: 2,
    DEFAULT_ZOOM_NEAR: 16,
    DEFAULT_ZOOM_REQUEST: 13,
    DISTANCE_REQUEST_KM: 5,
};

export default Config;
