import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import React from 'react';

class Step2Confirmar extends React.Component {
    render() {
        const { data, title, subtitle, text, requestPinAccept } = this.props;

        if (!data.id) {
            return (
                <BgPanel>
                    <div className="text-14">O pin solicitado não foi encontrado.</div>
                </BgPanel>
            );
        }

        return (
            <React.Fragment>
                <BgPanel>
                    <div className={'subtitle-15'}>{subtitle}</div>

                    <Spacer vertical={4} />

                    <div className={'title-26'}>{title}</div>

                    <Spacer vertical={4} />

                    <div className={'text-16'}>{text}</div>

                    <Spacer vertical={6} />
                </BgPanel>

                <Spacer vertical={4} horizontal={8}>
                    <DivSmart flex row spaceBetween>
                        <ButtonText
                            iconLeft={"<i class='fas fa-chevron-left'></i>"}
                            label={'Retornar'}
                            onClick={this.props.onBack}
                        />

                        <Spacer horizontal={10} />

                        <Button
                            fullwidth
                            label="Confirmar"
                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                            spaceBetween
                            onClick={this.props.onNext}
                            loading={requestPinAccept.loading}
                        />
                    </DivSmart>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect({ requestPinAccept: state`pin.requestPinAccept` }, Step2Confirmar);
