import { Module } from 'cerebral';
import { when } from 'cerebral/operators';
import { props } from 'cerebral/tags';
import get from 'lodash/get';
import { isDev } from 'utils/AppUtils';
import { replace } from 'utils/RouterUtils';
import { existStoredToken, getToken, logout, setToken, setUser } from './auth';
import { APIResponse } from './services';
import { createFeedback, post } from './services/APIService';
import { modal } from './services/modal/modal';

export const flowAuthUser = [getToken, setUser];

export let flowInitAuthUser = [
    getToken,
    existStoredToken,
    {
        success: [...flowAuthUser],
        error: [],
    },
];

export const flowLogin = [
    setToken,
    ...flowAuthUser,
    ({ props }) => {
        const to = get(props, 'response.result.data.to');

        if (to) {
            //vem do request (php)
            replace(to);
        } else if (props.to) {
            //vem do props (cerebral)
            replace(props.to);
        } else {
            //default
            replace('/mapa');
        }
    },
];

export default Module({
    state: {
        requestLogin: APIResponse(),
        token: null,
        user: null,

        requestAlterarSenha: APIResponse(),
        formAlterarSenha: {
            password: {
                value: isDev() ? 'omegatiger' : '',
                validationRules: ['isRequired', 'isPassword'],
            },
            repassword: {
                value: isDev() ? 'omegatiger' : '',
                validationRules: ['equalsField:auth.formAlterarSenha.password'],
                customErrorMessage: 'O campo de senha e confirmação da senha precisam ser iguais.',
            },
        },
    },

    signals: {
        alterarSenha: [
            prepareAlterarSenha,
            ...post('api/changePassword', 'auth.requestAlterarSenha', props`variables`),
            createFeedback(true, true),
            when(props`isSuccess`),
            {
                true: [...flowLogin],
                false: [],
            },
        ],
        deleteUser: [
            modal(
                {
                    title: 'Deseja realmente REMOVER sua conta?',
                },
                [
                    { path: 'cancelar', label: 'Retornar', buttonProps: { grayf3: true } },
                    { path: 'sim', label: 'Sim' },
                ]
            ),
            {
                sim: [
                    ...post('api/deleteUser', 'pin.requestDeleteUser', {}),
                    createFeedback(true, true),
                    when(props`isSuccess`),
                    {
                        true: [logout],
                        false: [],
                    },
                ],
                cancelar: [],
            },
        ],
        logout: [
            modal(
                {
                    title: 'Deseja realmente sair?',
                },
                [
                    { path: 'cancelar', label: 'Retornar', buttonProps: { grayf3: true } },
                    { path: 'sim', label: 'Sim' },
                ]
            ),
            {
                sim: [logout],
                cancelar: [],
            },
        ],
    },
});

function prepareAlterarSenha({ props, forms }) {
    let data = forms.toJSON('auth.formAlterarSenha');
    props.variables = data;
}
