import { Module } from 'cerebral';
import { set } from 'cerebral/operators';
import { state } from 'cerebral/tags';
import defaultConfig from '../defaultConfig.json';
import { flowInitAuthUser } from './AuthModule';
import { APIResponse, get } from './services';

export default Module({
    state: {
        ready: false,
        requestConfig: APIResponse(),
        config: null,

        menu: false,
    },
    signals: {
        initApp: [
            ...get('api/config', 'app.requestConfig'),
            {
                success: [set(state`app.config`, state`app.requestConfig.result.data`)],
                error: [
                    set(state`app.config`, defaultConfig.data),
                ],
            },
            ...flowInitAuthUser,
            set(state`app.ready`, true),
        ],

        openMenu: [set(state`app.menu`, true)],
        closeMenu: [set(state`app.menu`, false)],
    },
});
