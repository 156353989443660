import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BlockNotLoggedIn from 'components/BlockNoLoggedIn';
import Step1Categoria from 'pages/adicionarPedido/step1Categoria/Step1Categoria';
import Step2Endereco from 'pages/adicionarPedido/step2Endereco/Step2Endereco';
import Step3Descricao from 'pages/adicionarPedido/step3Descricao/Step3Descricao';
import Step4Revisao from 'pages/adicionarPedido/step4Revisao/Step4Revisao';
import React from 'react';
import { back } from 'utils/RouterUtils';

class AdicionarAjudaPage extends React.Component {
    state = {
        step: 1,
    };

    componentDidMount() {
        if (this.props.match.params.categoria_id) {
            this.props.signalSetField({
                path: 'pin.formAdd.ref_category',
                value: this.props.match.params.categoria_id,
            });

            this.onNext();
        }
    }

    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'pin.requestAdd' });
        this.props.signalResetForm({ path: 'pin.formAdd' });
    }

    onBack = () => {
        const { step } = this.state;
        if (step === 1) {
            back();
        } else {
            this.setState({ step: step - 1 });
        }
    };

    onNext = () => {
        const { step } = this.state;
        if (step === 4) {
            this.props.signalAddPin({ type: 'posso_ajudar' });
        } else {
            this.setState({ step: step + 1 });
        }
    };

    render() {
        const { step } = this.state;

        const { user } = this.props;

        return (
            <React.Fragment>
                {!user && (
                    <BlockNotLoggedIn
                        title="AJUDAR"
                        text="Para oferecer ajuda você precisa estar cadastrado."
                        to="/ajudar/adicionar-ajuda"
                    />
                )}

                {user && (
                    <React.Fragment>
                        {step === 1 && (
                            <Step1Categoria
                                title="AJUDAR"
                                text="Escolha uma categoria"
                                pink
                                onBack={this.onBack}
                                onNext={this.onNext}
                                type="oferecer-ajuda"
                            />
                        )}

                        {step === 2 && <Step2Endereco title="AJUDAR" pink onBack={this.onBack} onNext={this.onNext} />}

                        {step === 3 && (
                            <Step3Descricao
                                title="AJUDAR"
                                text={'Descreva de que forma você pode ajudar no contexto da situação:'}
                                pink
                                onBack={this.onBack}
                                onNext={this.onNext}
                            />
                        )}

                        {step === 4 && (
                            <Step4Revisao
                                title="AJUDAR"
                                text="Revise a sua ajuda antes de publicar:"
                                pink
                                onBack={this.onBack}
                                onNext={this.onNext}
                            />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default connect(
    {
        user: state`auth.user`,
        signalAddPin: signal`pin.addPin`,
        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
        signalSetField: signal`form.setField`,
    },
    AdicionarAjudaPage
);
