import { connect } from '@cerebral/react';
import { signal } from 'cerebral/tags';
import cls from 'classnames';
import React from 'react';
import { back } from 'utils/RouterUtils';
import styles from './BtnBack.module.scss';

const BtnBack = ({ signalCloseMenu }) => {
    const onBackClick = () => {
        signalCloseMenu();
        back();
    };

    return (
        <div to="/" className={cls(styles.root, 'BtnBack')} onClick={onBackClick}>
            <i className="fas fa-chevron-left"></i> Retornar
        </div>
    );
};

export default connect({ signalCloseMenu: signal`app.closeMenu` }, BtnBack);
