//https://developers.google.com/analytics/devguides/collection/gtagjs/migration#measure_events

export function trackEvent(category, action, label) {
    // console.log(category, action, label, window['dataLayer']);

    // window['ga']('send', {
    //     hitType: 'event',
    //     eventCategory: category,
    //     eventAction: action,
    //     eventLabel: label,
    //     event: `${category} | ${action} | ${label}`,
    // });

    if (window['dataLayer']) {
        // window['dataLayer'].push({
        //     gaCategory: category,
        //     gaAction: action,
        //     gaLabel: label,
        //     event: `${category} | ${action} | ${label}`,
        // });

        window['gtag']('event', action, {
            event_category: category,
            event_label: label,
        });
    }
}

export function trackPageview(pageview) {
    // console.log(pageview, window['dataLayer']);

    if (window['dataLayer']) {
        window['gtag']('config', 'UA-162732497-1', {
            page_path: pageview,
        });

        // window['dataLayer'].push({
        //     event: 'pageview',
        //     virtualPagePath: pageview,
        // });
    }
}
