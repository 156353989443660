import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import classnames from 'classnames';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Modal from 'components/Modal/Modal';
import Spacer from 'components/Spacer';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { openModalApi } from 'utils/AppUtils';
import { trackEvent } from 'utils/GAUtil';
import styles from './ModalAlias.module.scss';
class ModalAlias extends React.Component {
    static defaultProps = {};

    static propTypes = {};

    componentDidMount() {
        this.props.fetch({ alias: this.props.alias });
    }

    onCloseClick = () => {
        this.props.signalCloseModal();
    };

    onClick = () => {
        trackEvent('precisamos', 'modal_apoio_solicitar_ajuda', this.props.alias);

        this.props.signalCloseModal();
    };

    render() {
        const { requestModalApi } = this.props;

        if (requestModalApi && requestModalApi.loading) {
            return (
                <Modal visible={true} onClose={this.onCloseClick}>
                    <div className={styles.modal}>
                        <Loading />
                    </div>
                </Modal>
            );
        }

        if (requestModalApi.result && requestModalApi.result.data) {
            const { image, title, text, label, link, alias, target } = requestModalApi.result.data;

            return (
                <Modal visible={true} onClose={this.onCloseClick}>
                    <div className={styles.modal}>
                        <div className={classnames('fas', 'fa-times', styles.btnClose)} onClick={this.onCloseClick} />

                        <div className={classnames(styles.content)}>
                            <img className={styles.image} src={image} alt={title} />

                            <Spacer vertical={8} horizontal={8}>
                                <div className={'title-22'} dangerouslySetInnerHTML={{ __html: title }}></div>

                                <Spacer vertical={2} />

                                <div className={'text-16-blue'} dangerouslySetInnerHTML={{ __html: text }}></div>

                                <Spacer vertical={2} />

                                <div className={styles.btn}>
                                    <SmartLink onClick={this.onClick} to={link} target={target}>
                                        <Button
                                            label={label}
                                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                                            spaceBetween
                                        />
                                    </SmartLink>
                                </div>
                            </Spacer>
                        </div>
                    </div>
                </Modal>
            );
        }

        return null;
    }
}

export const SmartLink = ({ to, target, onClick = null, children, ...props }) => {
    if (target === '_modal') {
        return (
            <div
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }

                    openModalApi(to);
                }}
                {...props}
            >
                {children}
            </div>
        );
    }

    return target === '_blank' ? (
        <a href={to} target={target} {...props} onClick={onClick}>
            {children}
        </a>
    ) : (
        <Link to={to} target={target} {...props} onClick={onClick}>
            {children}
        </Link>
    );
};

//withRouter
export default connect(
    {
        fetch: signal`modalApi.fetch`,
        requestModalApi: state`modalApi.requestModalApi`,
        signalCloseModal: signal`modalApi.closeModal`,
    },
    ModalAlias
);
