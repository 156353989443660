import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BlockNotLoggedIn from 'components/BlockNoLoggedIn';
import React from 'react';
import { back } from 'utils/RouterUtils';
import Step1Categoria from './step1Categoria/Step1Categoria';
import Step2Endereco from './step2Endereco/Step2Endereco';
import Step3Descricao from './step3Descricao/Step3Descricao';
import Step4Revisao from './step4Revisao/Step4Revisao';

class AdicionarPedidoPage extends React.Component {
    state = {
        step: 1,
    };

    componentDidMount() {
        if (this.props.match.params.categoria_id) {
            this.props.signalSetField({
                path: 'pin.formAdd.ref_category',
                value: this.props.match.params.categoria_id,
            });

            this.onNext();
        }
    }

    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'pin.requestAdd' });
        this.props.signalResetForm({ path: 'pin.formAdd' });
    }

    onBack = () => {
        const { step } = this.state;
        if (step === 1) {
            back();
        } else {
            this.setState({ step: step - 1 });
        }
    };

    onNext = () => {
        const { step } = this.state;
        if (step === 4) {
            this.props.signalAddPin({ type: 'pedir_ajuda' });
        } else {
            this.setState({ step: step + 1 });
        }
    };

    render() {
        const { step } = this.state;

        const { user } = this.props;

        return (
            <React.Fragment>
                {!user && (
                    <BlockNotLoggedIn
                        title="PEDIDOS"
                        text="Para pedir ajuda você precisa estar cadastrado."
                        to="/pedidos/adicionar-pedido"
                    />
                )}

                {user && (
                    <React.Fragment>
                        {step === 1 && <Step1Categoria onBack={this.onBack} onNext={this.onNext} />}
                        {step === 2 && <Step2Endereco onBack={this.onBack} onNext={this.onNext} />}
                        {step === 3 && <Step3Descricao onBack={this.onBack} onNext={this.onNext} />}
                        {step === 4 && <Step4Revisao onBack={this.onBack} onNext={this.onNext} />}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default connect(
    {
        user: state`auth.user`,
        signalAddPin: signal`pin.addPin`,
        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
        signalSetField: signal`form.setField`,
    },
    AdicionarPedidoPage
);
