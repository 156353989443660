import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import styles from './Input.module.scss';

const Input = ({ type, placeholder, error, mask, value, onChange, onBlur, onKeyPress, maxLength }) => {
    const propsInput = {
        type,
        className: styles.input,
        placeholder,

        onChange,
        onBlur,
        onKeyPress,
        maxLength,
        value,
    };
    return (
        <div className={classnames(styles.Input, { [styles.error]: !!error })}>
            {!!mask && <InputMask {...propsInput} mask={mask} />}

            {!mask && <input autocomplete="off" {...propsInput} />}

            {!!error && <div className={styles.errorText}>{error}</div>}
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
    placeholder: '',
};

Input.propTypes = {
    type: PropTypes.oneOf(['text', 'password']),
    placeholder: PropTypes.string,
    error: PropTypes.string,
    mask: PropTypes.string,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    touched: PropTypes.bool,
};

export default Input;
