import { Module } from 'cerebral';
import { when } from 'cerebral/operators';
import { props } from 'cerebral/tags';
import { isDev } from 'utils/AppUtils';
import { back } from 'utils/RouterUtils';
import { flowLogin } from './AuthModule';
import { APIResponse, post } from './services';
import { createFeedback } from './services/APIService';

export default Module({
    state: {
        requestForgot: APIResponse(),

        formForgot: {
            email: {
                value: isDev() ? 'omegatiger@gmail.com' : '',
                validationRules: ['isRequired', 'isEmail'],
            },
        },

        requestAlterarSenha: APIResponse(),

        formAlterarSenha: {
            code: {
                value: '',
            },
            email: {
                value: '',
            },
            password: {
                value: '',
                validationRules: ['isRequired', 'isPassword'],
            },
            repassword: {
                value: '',
                validationRules: ['equalsField:forgot.formAlterarSenha.password'],
                customErrorMessage: 'O campo de senha e confirmação da senha precisam ser iguais.',
            },
        },

        requestEsqueciEmail: APIResponse(),
        formEsqueciEmail: {
            cpf_cnpj: {
                value: isDev() ? '008.361.150.90' : '',
                validationRules: ['isCpfCnpj'],
            },
        },
    },
    signals: {
        forgot: [
            prepareForgot,
            ...post('api/forgot', 'forgot.requestForgot', props`variables`),
            createFeedback(true, true),
            when(props`isSuccess`),
            {
                true: [
                    () => {
                        back();
                    },
                ],
                false: [],
            },
        ],

        alterarSenha: [
            prepareAlterarSenha,
            ...post('api/forgotChangePassword', 'forgot.requestAlterarSenha', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [...flowLogin],
                false: [],
            },
        ],

        esqueciEmail: [
            prepareEsqueciEmail,
            ...post('api/forgotEmail', 'forgot.requestEsqueciEmail', props`variables`),
            createFeedback(true, true),
            when(props`isSuccess`),
            {
                true: [
                    () => {
                        back();
                    },
                ],
                false: [],
            },
        ],
    },
});

function prepareForgot({ state, props, forms }) {
    let data = forms.toJSON('forgot.formForgot');
    props.variables = data;
}

function prepareAlterarSenha({ state, props, forms }) {
    let data = forms.toJSON('forgot.formAlterarSenha');
    data.code = props.code;
    data.email = props.email;

    props.variables = data;
}

function prepareEsqueciEmail({ state, props, forms }) {
    let data = forms.toJSON('forgot.formEsqueciEmail');
    props.variables = data;
}
