import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import propTypes from 'prop-types';
import React from 'react';
import { modalFeedback } from './modal';
import ModalComponent from './ModalComponent';

class ModalContainer extends React.Component {
    static propTypes = {
        items: propTypes.array,
        loading: propTypes.object,
    };

    renderList = () => {
        if (this.props.items.length > 0) {
            const data = this.props.items[this.props.items.length - 1];

            return (
                <ModalComponent
                    onButtonClick={path => {
                        modalFeedback(data.id, path.path);
                    }}
                    buttons={data.paths}
                    title={data.title}
                    key={data.id}
                />
            );
        }

        return null;
    };

    render() {
        // let hasLoading = false;
        let loadingNames = [];

        for (let loadingKey in this.props.loading) {
            if (this.props.loading[loadingKey] === true) {
                // hasLoading = true;
                loadingNames.push(loadingKey);
            }
        }

        return this.renderList();

        // return [this.renderList(), <ModalLoading key="loading" info={loadingNames.join(',')} visible={hasLoading} />];
    }
}

export default connect({ items: state`modal.list`, loading: state`modal.loading` }, ModalContainer);
