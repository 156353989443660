import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BlockNotLoggedIn from 'components/BlockNoLoggedIn';
import React from 'react';
import ListaAjudas from './listaAjudas/ListaAjudas';

class AjudarPage extends React.Component {
    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'pin.requestListPin' });
    }
    render() {
        const { user } = this.props;

        return (
            <React.Fragment>
                {!user && (
                    <BlockNotLoggedIn
                        title="AJUDAR"
                        text="Para oferecer ajuda você precisa estar cadastrado."
                        to="/ajudar"
                    />
                )}

                {user && <ListaAjudas />}
            </React.Fragment>
        );
    }
}

export default connect({ user: state`auth.user`, signalResetRequest: signal`form.resetRequest` }, AjudarPage);
