import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import ComponentConnect from 'components/ComponentConnect';
import DivSmart from 'components/DivSmart';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import React from 'react';
import { back, go } from 'utils/RouterUtils';

class ConfirmarCodigoPage extends React.Component {
    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'cadastro.requestCodigo' });
        this.props.signalResetForm({ path: 'cadastro.formCodigo' });
    }

    onRetornarPress = () => {
        back();
    };

    onConfirmarPress = () => {
        const { formCodigo } = this.props;

        if (formCodigo.isValid) {
            this.props.signalCodigo();
        } else {
            this.props.touchForm({ form: 'cadastro.formCodigo' });
        }
    };

    onResendClick = () => {
        go('/confirmar-codigo-email');
    };

    render() {
        const { formCodigo, requestCodigo } = this.props;

        const { params } = this.props.match;

        return (
            <BgPanel>
                <div className={'subtitle-15'}>Confirmar Código</div>

                <Spacer vertical={4} />

                <div className={'title-26'}>Digite o código recebido por e-mail.</div>

                <Spacer vertical={4} />

                <ComponentConnect
                    path="cadastro.formCodigo.code"
                    value={params.code && params.code !== '0' ? params.code : ''}
                >
                    <Input placeholder="Código" maxLength={6} />
                </ComponentConnect>

                <Spacer vertical={2} />

                <ComponentConnect path="cadastro.formCodigo.email" value={this.props.match.params.email}>
                    <Input placeholder="E-mail" />
                </ComponentConnect>

                <Spacer vertical={2} />

                <ButtonText
                    iconLeft={"<i class='far fa-paper-plane'></i>"}
                    label={'Reenviar código de ativação'}
                    onClick={this.onResendClick}
                />

                <Spacer vertical={2} />

                <DivSmart flex flex1 />

                <DivSmart flex row spaceBetween>
                    <ButtonText
                        iconLeft={"<i class='fas fa-chevron-left'></i>"}
                        label={'Retornar'}
                        onClick={this.onRetornarPress}
                    />

                    <Spacer horizontal={10} />

                    <Button
                        fullwidth
                        label="Avançar"
                        iconRight={"<i class='fas fa-chevron-right'></i>"}
                        spaceBetween
                        onClick={this.onConfirmarPress}
                        loading={requestCodigo.loading}
                        disabledStyle={!formCodigo.isValid}
                    />
                </DivSmart>
            </BgPanel>
        );
    }
}

export default connect(
    {
        formCodigo: form(state`cadastro.formCodigo`),

        requestCodigo: state`cadastro.requestCodigo`,
        signalCodigo: signal`cadastro.codigo`,

        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
        touchForm: signal`form.touchForm`,
    },
    ConfirmarCodigoPage
);
