import { Module } from 'cerebral';
import { when } from 'cerebral/operators';
import { props } from 'cerebral/tags';
import { isDev } from 'utils/AppUtils';
import { back, replace } from 'utils/RouterUtils';
import { flowLogin } from './AuthModule';
import { APIResponse, post } from './services';
import { createFeedback } from './services/APIService';

export default Module({
    state: {
        requestCadastro: APIResponse(),

        formCadastro: {
            name: {
                value: isDev() ? 'Rafael' : '',
                validationRules: ['isRequired'],
            },
            dt_birthday: {
                value: isDev() ? '25/04/1986' : '',
                validationRules: ['isDate'],
            },
            phone: {
                value: isDev() ? '54 32282316' : '',
                validationRules: ['isRequired'],
            },
            email: {
                value: isDev() ? 'omegatiger@gmail.com' : '',
                validationRules: ['isRequired', 'isEmail'],
            },
            reemail: {
                value: isDev() ? 'omegatiger@gmail.com' : '',
                validationRules: ['isRequired', 'isEmail', 'equalsField:cadastro.formCadastro.email'],
                customErrorMessage: 'O campo de e-mail e confirmação precisam ser iguais.',
            },
            password: {
                value: isDev() ? 'omegatiger' : '',
                validationRules: ['isRequired', 'isPassword'],
            },
            repassword: {
                value: isDev() ? 'omegatiger' : '',
                validationRules: ['equalsField:cadastro.formCadastro.password'],
                customErrorMessage: 'O campo de senha e confirmação da senha precisam ser iguais.',
            },
            flTermo: {
                value: false,
                validationRules: ['isTrue'],
                customErrorMessage: 'Você precisa aceitar os termos de uso para prosseguir',
            },
            city: {
                value: '',
                validationRules: ['isRequired'],
            },
            lat: {
                value: '',
                validationRules: ['isRequired'],
            },
            lng: {
                value: '',
                validationRules: ['isRequired'],
            },
            provider: {
                value: '',
            },
            signed_request: {
                value: '',
            },
            social_id: {
                value: '',
            },
        },

        requestCodigo: APIResponse(),

        formCodigo: {
            code: {
                value: isDev() ? '1111' : '',
                validationRules: ['isRequired'],
            },
            email: {
                value: isDev() ? 'omegatiger@gmail.com' : '',
                validationRules: ['isRequired', 'isEmail'],
            },
        },

        formResendCodigo: {
            email: {
                value: isDev() ? 'omegatiger@gmail.com' : '',
                validationRules: ['isRequired', 'isEmail'],
            },
        },

        requestResendCodigo: APIResponse(),
    },
    signals: {
        cadastro: [
            prepareCadastro,
            ...post('api/cadastro', 'cadastro.requestCadastro', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [
                    ({ props }) => {
                        console.log('RESULT PROPS', props);
                    },

                    when(props`response.result.data.token`),
                    {
                        true: [
                            ...flowLogin,
                        ],
                        false: [
                            ({ props }) => {
                                replace('/confirmar-codigo/0/' + props.variables.email);
                            },
                        ],
                    },
                ],
                false: [],
            },
        ],

        codigo: [
            prepareCodigo,
            ...post('api/confirmCode', 'cadastro.requestCodigo', props`variables`),
            createFeedback(false, true),
            when(props`isSuccess`),
            {
                true: [...flowLogin],
                false: [],
            },
        ],

        resendCodigo: [
            prepareResendCodigo,
            ...post('api/resendCode', 'cadastro.requestResendCodigo', props`variables`),
            createFeedback(true, true),
            when(props`isSuccess`),
            {
                true: [
                    () => {
                        back();
                    },
                ],
                false: [],
            },
        ],

        setFormValidationSocial: [setFormValidationSocial],
    },
});

function setFormValidationSocial({ state }) {
    state.set('cadastro.formCadastro.email.validationRules', []);
    state.set('cadastro.formCadastro.reemail.validationRules', []);
    state.set('cadastro.formCadastro.password.validationRules', []);
    state.set('cadastro.formCadastro.repassword.validationRules', []);
}

function prepareCadastro({ props, forms }) {
    let data = forms.toJSON('cadastro.formCadastro');
    data.to = props.to;

    props.variables = data;
}

function prepareCodigo({ props, forms }) {
    let data = forms.toJSON('cadastro.formCodigo');
    props.variables = data;
}

function prepareResendCodigo({ props, forms }) {
    let data = forms.toJSON('cadastro.formResendCodigo');
    props.variables = data;
}
