import classnames from 'classnames';
import Button from 'components/Button';
import Spacer from 'components/Spacer';
import React from 'react';
import Slider from 'react-slick';
import { go } from 'utils/RouterUtils';
import styles from './SliderInfo.module.scss';

const Arrow = function ({ next, prev, className, style, onClick }) {
    return (
        <div className={classnames(className, styles.arrow)} onClick={onClick} style={{ ...style }}>
            {next && <i class="fas fa-chevron-right"></i>}
            {prev && <i class="fas fa-chevron-left"></i>}
        </div>
    );
};

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    nextArrow: <Arrow next />,
    prevArrow: <Arrow prev />,
};

class SliderInfo extends React.Component {
    state = {
        index: 0,
    };

    onAfterChange = (index) => {
        this.setState({ index });
    };

    onMapaClick = () => {
        localStorage.setItem('precisamos_slider_info', true);
        go('/mapa');
    };

    render() {
        return (
            <div className={styles.root}>
                <div className={styles.content}>
                    <div className={classnames('fas', 'fa-times', styles.btnClose)} onClick={this.onMapaClick}></div>

                    <div className={styles.slider}>
                        <Slider {...settings} afterChange={this.onAfterChange}>
                            <div className={classnames(styles.sliderItem)}>
                                <div className={styles.logo}>
                                    <img src={require('./assets/logo.png')} alt="" />
                                </div>

                                <Spacer vertical={2} />

                                <div className={styles.text}>
                                    <div className="color-gray6b">Aproximamos</div>
                                    <div className="color-lightblue font-medium">quem precisa de ajuda</div>
                                    <div className="color-pink font-medium">
                                        <span className="color-gray6b font-regular">com</span> quem quer ajudar.
                                    </div>
                                </div>

                                <Spacer vertical={4} />

                                <div className={styles.image1}>
                                    <img src={require('./assets/step1.png')} alt="" />
                                </div>
                            </div>

                            <div className={classnames(styles.sliderItem)}>
                                <Spacer vertical={4} />

                                <div className={classnames(styles.text)}>
                                    O <span className="color-darkblue font-bold">precisamos.com.br</span> é uma
                                    iniciativa simples para conectar pessoas em dificuldade com aquelas dispostas a
                                    ajudar. Basta postar o seu PIN no mapa{' '}
                                    <span className="color-lightblue font-bold">solicitando</span> ou{' '}
                                    <span className="color-pink font-bold">oferecendo</span> ajuda.
                                </div>

                                <Spacer vertical={4} />

                                <div className={styles.image2}>
                                    <img src={require('./assets/step2.png')} alt="" />
                                </div>
                            </div>

                            <div className={styles.sliderItem}>
                                <Spacer vertical={2} />

                                <div className={styles.title}>CADASTRO</div>

                                <Spacer vertical={2} />

                                <div className={styles.text}>
                                    Você realiza o seu cadastro de plataforma informando os dados básicos de nome, data
                                    de nascimento e e-mail.
                                </div>

                                <Spacer vertical={4} />

                                <div className={styles.image1}>
                                    <img src={require('./assets/step3.png')} alt="" />
                                </div>
                            </div>

                            <div className={styles.sliderItem}>
                                <Spacer vertical={2} />

                                <div className={styles.title}>PIN NO MAPA</div>

                                <Spacer vertical={2} />

                                <div className={styles.text}>
                                    No mapa, você pode criar os PINs{' '}
                                    <span className="color-lightblue font-medium">pedindo</span> ajuda ou{' '}
                                    <span className="color-pink font-medium">oferecendo</span> ajuda. Não esqueça de
                                    compartihá-los em suas redes sociais.
                                </div>

                                <Spacer vertical={4} />

                                <div className={styles.image1}>
                                    <img src={require('./assets/step1.png')} alt="" />
                                </div>
                            </div>

                            <div className={styles.sliderItem}>
                                <Spacer vertical={2} />

                                <div className={styles.title}>MATCH!</div>

                                <Spacer vertical={2} />

                                <div className={classnames(styles.text, styles.textSmall)}>
                                    Encontrou algum PIN perto de você{' '}
                                    <span className="color-lightblue font-medium">pedindo ajuda?</span> Tem alguém{' '}
                                    <span className="color-pink font-medium">oferecendo ajuda</span> próximo de você?
                                    Basta <span className="color-pink font-medium">oferecer</span> ou{' '}
                                    <span className="color-lightblue font-medium">solicitar</span> ajuda e nós
                                    conectamos ambas as partes por e-mail. Assim permitimos que combinem a melhor forma
                                    de se ajudar.
                                </div>

                                <Spacer vertical={4} />

                                <div className={styles.image5}>
                                    <img src={require('./assets/step5.png')} alt="" />
                                </div>
                            </div>

                            <div className={styles.sliderItem}>
                                <Spacer vertical={2} />

                                <div className={styles.title}>Pronto, vamos começar?</div>

                                <Spacer vertical={2} />

                                <div className={styles.text}>
                                    Crie o seu primeiro PIN e compartilhe
                                    <br /> sua <span className="color-pink font-medium">oferta</span> ou{' '}
                                    <span className="color-lightblue font-medium">pedido</span> de ajuda.
                                </div>

                                <Spacer vertical={4} />

                                <div className={styles.image5}>
                                    <img src={require('./assets/step6.png')} alt="" />
                                </div>
                            </div>
                        </Slider>

                        <Spacer vertical={4} />

                        <Spacer horizontal={5}>
                            <Button
                                className={styles.btnVerMapa}
                                label="Ver Mapa"
                                disabled={this.state.index < 5}
                                onClick={this.onMapaClick}
                            ></Button>
                        </Spacer>
                    </div>

                    {/* <Spacer vertical={2} />

                    <Link to="/mapa">
                        <Button label="Ver Mapa"></Button>
                    </Link> */}
                </div>
            </div>
        );
    }
}

export default SliderInfo;
