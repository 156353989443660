import classnames from 'classnames';
import Input from 'components/Input';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styles from './InputSearch.module.scss';

class InputSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { address: '' };
    }

    handleChange = (address) => {
        this.setState({ address });
    };

    onPlaceSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                const { lat, lng } = latLng;

                this.props.onChange({ lat, lng });

                this.setState({ address }, () => {
                    if (document.activeElement) {
                        document.activeElement.blur();
                    }
                });
            })
            .catch((error) => {
                alert('Houve um erro, digite novamente o endereço.');
            });
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onSelect={this.onPlaceSelect}
                onChange={this.handleChange}
                searchOptions={{ componentRestrictions: { country: ['br'] } }}
                debounce={1000}
                shouldFetchSuggestions={this.state.address && this.state.address.length > 4 ? true : false}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={styles.root}>
                        <Input {...getInputProps({ placeholder: 'Digite seu endereço' })} />

                        <div className={styles.containerAutoComplete}>
                            {loading && <div className={styles.loading}>Carregando ...</div>}

                            {suggestions.map((suggestion) => {
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className: classnames(styles.item, {
                                                [styles.itemSelected]: suggestion.active,
                                            }),
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default InputSearch;
