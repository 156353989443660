import HttpProvider from '@cerebral/http';
import Config from 'Config';

export default HttpProvider({
    baseUrl: Config.ENDPOINT,

    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8;',
        // 'X-Requested-With': 'XMLHttpRequest',
    },

    withCredentials: false,

    // timeout: 5000,
});
