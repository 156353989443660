import Controller from 'modules/Controller';
import { trackEvent } from './GAUtil';

export function isDev() {
    return process.env.NODE_ENV === 'development';
}

export function isMobile() {
    const width = Math.max(window.screen.width, window.innerWidth);
    return width < 1024;
}

export function distance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    // if (d > 1) return Math.round(d) + 'km';
    // else if (d <= 1) return Math.round(d * 1000) + 'm';
    //KM
    return d;
}

export function openModalApi(alias) {
    trackEvent('precisamos', 'modal_apoio', alias);

    const signalOpenModal = Controller.getSignal('modalApi.openModal');
    signalOpenModal({ alias });
}

export function getCity(results) {
    let address_components = results[0].address_components;
    let foundCity = address_components.filter((v) => v.types.indexOf('administrative_area_level_2') > -1);

    //Normaliza a cidade
    let city = address_components[0].long_name;
    if (foundCity[0]) {
        city = foundCity[0].long_name;
    }

    return city;
}
