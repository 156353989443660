import cls from 'classnames';
import BtnBack from 'pages/infoBase/btnBack/BtnBack';
import React from 'react';
import texts from 'texts.json';
import '../infoBase/InfoBase.scss';
import styles from './PrivacidadePage.module.scss';

//Utilizado na versão mobile
export default () => {
    return (
        <div className={cls(styles.root, 'InfoBase')}>
            <BtnBack></BtnBack>
            <div className="wrap">
                <div className="subtitle-15">POLÍTICA DE PRIVACIDADE</div>

                <div className={cls('text-18')} dangerouslySetInnerHTML={{ __html: texts.politica }}></div>
            </div>
        </div>
    );
};
