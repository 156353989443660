import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Check.module.scss';

const Check = ({ label, value, onChange, error }) => {
    const onClick = () => {
        onChange(!value);
    };

    return (
        <React.Fragment>
            <div
                onClick={onClick}
                className={classnames(styles.check, {
                    [styles.checked]: value,
                })}
            >
                <div className={styles.icon}>
                    {value && <i className="far fa-check-square"></i>}
                    {!value && <i className="far fa-square"></i>}
                </div>

                <div className={classnames(styles.label)}>{label}</div>
            </div>
            {!!error && <div className={styles.errorText}>{error}</div>}
        </React.Fragment>
    );
};

Check.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.any,
};

export default Check;
