import { Controller, Module } from 'cerebral';
import Devtools from 'cerebral/devtools';
import Config from 'Config';
import AppModule from './AppModule';
import AuthModule from './AuthModule';
import CadastroModule from './CadastroModule';
import ForgotModule from './ForgotModule';
import FormModule from './FormModule';
import LoginModule from './LoginModule';
import ModalApiModule from './ModalApiModule';
import PinModule from './PinModule';
import FormsProvider from './providers/FormsProvider';
import HttpProvider from './providers/HttpProvider';
import ModalModule from './services/modal/modal';

const devtools = process.env.NODE_ENV === 'development' ? Devtools({ host: Config.CEREBRAL_DEBUGGER }) : null;

const rootModule = Module({
    state: {},
    signals: {},
    modules: {
        app: AppModule,
        form: FormModule,
        auth: AuthModule,
        modal: ModalModule,
        login: LoginModule,
        forgot: ForgotModule,
        cadastro: CadastroModule,
        pin: PinModule,
        modalApi: ModalApiModule,
    },
    providers: {
        http: HttpProvider,
        forms: FormsProvider,
    },
});

export default Controller(rootModule, {
    devtools: devtools,
});
