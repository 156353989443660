import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import Check from 'components/Check';
import ComponentConnect from 'components/ComponentConnect';
import DivSmart from 'components/DivSmart';
import Input from 'components/Input';
import InputCity from 'components/InputCity';
import ModalSimple from 'components/ModalSimple';
import Spacer from 'components/Spacer';
import get from 'lodash/get';
import React from 'react';
import texts from 'texts.json';
import { back } from 'utils/RouterUtils';
import styles from './CadastroPage.module.scss';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

class CadastroPage extends React.Component {
    state = {
        showTerms: false,

        social: false,
        hideEmail: false,
    };

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.provider) {
            this.withSocial(this.props.location.state);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevProvider = get(prevProps, 'location.state.provider');
        const nextProvider = get(this.props, 'location.state.provider');

        if (prevProvider !== nextProvider) {
            this.withSocial(this.props.location.state);
        }
    }

    withSocial(state) {
        const { provider, name, email, signed_request, social_id } = state;

        this.props.signalSetField({ path: 'cadastro.formCadastro.provider', value: provider });
        this.props.signalSetField({ path: 'cadastro.formCadastro.name', value: name });
        this.props.signalSetField({ path: 'cadastro.formCadastro.email', value: email });
        this.props.signalSetField({ path: 'cadastro.formCadastro.signed_request', value: signed_request });
        this.props.signalSetField({ path: 'cadastro.formCadastro.social_id', value: social_id });

        this.props.signalSetFormValidationSocial();

        this.setState({
            social: true,
            hideEmail: email ? true : false,
        });
    }

    componentWillUnmount() {
        this.props.signalResetRequest({ path: 'cadastro.requestCadastro' });
        this.props.signalResetForm({ path: 'cadastro.formCadastro' });
    }

    responseFacebook = (response) => {
        console.log('responseFacebook', response);

        if (response.id) {
            this.props.signalLoginSocial({ response });
        } else {
            alert('Erro ao autenticar com o Facebook, tente novamente.');
        }
    };

    responseGoogle = (response) => {
        console.log('responseGoogle', response);

        if (response.credential) {
            this.props.signalLoginSocial({ response: response.credential });
        } else {
            alert(response.error);
        }
    };

    onCadastroClick = () => {
        const { to } = this.props.location.state || { to: '' };
        const { formCadastro } = this.props;

        if (formCadastro.isValid) {
            this.props.signalCadastro({ to });
        } else {
            this.props.touchForm({ form: 'cadastro.formCadastro' });
        }
    };

    onRetornarClick = () => {
        back();
    };

    onTermsClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        this.setState({ showTerms: true });
    };

    onFirstTermsClick = (evt) => {
        if (this.firstTermsClick) {
            return;
        }

        this.firstTermsClick = true;

        this.setState({ showTerms: true });
    };

    onTermsCloseClick = () => {
        this.setState({ showTerms: false });
    };

    onChangeLatLng = ({ lat, lng }) => {
        this.props.signalSetField({ path: 'cadastro.formCadastro.lat', value: lat * 1 });
        this.props.signalSetField({ path: 'cadastro.formCadastro.lng', value: lng * 1 });
    };

    render() {
        const { social, hideEmail } = this.state;

        const { formCadastro, requestCadastro } = this.props;

        return (
            <React.Fragment>
                <ModalSimple visible={this.state.showTerms} title="Termos de Uso" onClose={this.onTermsCloseClick}>
                    <div style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: texts.termos }} />
                    <Button label="Fechar" onClick={this.onTermsCloseClick} />
                </ModalSimple>

                <BgPanel>
                    <div className={'subtitle-15'}>IDENTIFICAÇÃO</div>

                    <Spacer vertical={4} />

                    {!social && (
                        <React.Fragment>
                            <div className="label-18">Cadastro Rápido</div>

                            <div className={styles.blockSocials}>
                                {/* <ReactFacebookLogin
                                    appId="2682061918730814"
                                    fields="name,email"
                                    callback={this.responseFacebook}
                                    autoLoad={false}
                                    disableMobileRedirect={true}
                                    redirectUri="https://precisamos.com.br/cadastro"
                                    cookie={true}
                                    xfbml={true}
                                    isMobile={isMobile()}
                                    render={(renderProps) => (
                                        <Button
                                            fullwidth
                                            onClick={() => {
                                                this.props.history.push('/cadastro');
                                                renderProps.onClick();
                                            }}
                                            iconLeft={"<i class='fab fa-facebook-f' style='font-size:22px;'></i>"}
                                            pink
                                            style={{ backgroundColor: '#3b5998' }}
                                        />
                                    )}
                                />

                                <Spacer horizontal={2} /> */}

                                <GoogleOAuthProvider clientId="974995813547-m8dmfkpund7k2gv8s0uom6k68758vhfo.apps.googleusercontent.com">
                                    <GoogleLogin
                                        clientId="974995813547-m8dmfkpund7k2gv8s0uom6k68758vhfo.apps.googleusercontent.com"
                                        onSuccess={this.responseGoogle}
                                        onError={this.responseGoogle}
                                    ></GoogleLogin>
                                </GoogleOAuthProvider>

                                {/* <GoogleLogin
                                    clientId="974995813547-m8dmfkpund7k2gv8s0uom6k68758vhfo.apps.googleusercontent.com"
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    isSignedIn={false}
                                    render={(renderProps) => (
                                        <Button
                                            fullwidth
                                            onClick={renderProps.onClick}
                                            iconLeftObject={
                                                <img
                                                    src={require('./../login/assets/google.png')}
                                                    alt=""
                                                    style={{ width: '22px', height: 'auto', marginTop: '-2px' }}
                                                />
                                            }
                                            white
                                            style={{ backgroundColor: 'white', border: '1px solid #6b727a' }}
                                        />
                                    )}
                                /> */}
                            </div>
                            <Spacer vertical={3} />
                        </React.Fragment>
                    )}

                    <form autocomplete="off">
                        <div className="label-18">Nome</div>
                        <ComponentConnect path="cadastro.formCadastro.name">
                            <Input placeholder="Apenas o primeiro nome" />
                        </ComponentConnect>

                        <Spacer vertical={3} />

                        <div className="label-18">Sua Cidade</div>
                        <ComponentConnect path="cadastro.formCadastro.city">
                            <InputCity placeholder="Informe sua cidade" onChangeLatLng={this.onChangeLatLng} />
                        </ComponentConnect>

                        <Spacer vertical={3} />

                        <div className="label-18">Data de Nascimento</div>
                        <ComponentConnect path="cadastro.formCadastro.dt_birthday">
                            <Input type="tel" placeholder="Dia / Mês / Ano" mask="99/99/9999" />
                        </ComponentConnect>

                        <Spacer vertical={3} />

                        <div className="label-18">Telefone</div>
                        <ComponentConnect path="cadastro.formCadastro.phone">
                            <Input type="tel" placeholder="Telefone" mask="99 999999999" />
                        </ComponentConnect>

                        <Spacer vertical={3} />

                        {!hideEmail && (
                            <React.Fragment>
                                <div className="label-18">E-mail*</div>
                                <ComponentConnect path="cadastro.formCadastro.email">
                                    <Input placeholder="E-mail" />
                                </ComponentConnect>

                                <Spacer vertical={3} />

                                <div className="label-18">Confirmar E-mail*</div>

                                <ComponentConnect path="cadastro.formCadastro.reemail">
                                    <Input placeholder="Confirme seu E-mail" />
                                </ComponentConnect>

                                <Spacer vertical={3} />
                            </React.Fragment>
                        )}

                        {!social && (
                            <React.Fragment>
                                <div className="label-18">Senha*</div>
                                <ComponentConnect path="cadastro.formCadastro.password">
                                    <Input placeholder="Senha" type="password" />
                                </ComponentConnect>

                                <Spacer vertical={3} />

                                <div className="label-18">Confirmar Senha*</div>

                                <ComponentConnect path="cadastro.formCadastro.repassword">
                                    <Input placeholder="Confirme sua senha" type="password" />
                                </ComponentConnect>

                                <Spacer vertical={3} />
                            </React.Fragment>
                        )}
                    </form>

                    <ComponentConnect path="cadastro.formCadastro.flTermo">
                        <Check
                            label={
                                <div onClick={this.onFirstTermsClick}>
                                    eu aceito os{' '}
                                    <span className={'link'} onClick={this.onTermsClick}>
                                        termos de uso
                                    </span>
                                </div>
                            }
                        />
                    </ComponentConnect>
                </BgPanel>

                <Spacer vertical={4} horizontal={8}>
                    <DivSmart flex row spaceBetween>
                        <ButtonText
                            iconLeft={"<i class='fas fa-chevron-left'></i>"}
                            label={'Retornar'}
                            onClick={this.onRetornarClick}
                        />

                        <Spacer horizontal={10} />

                        <Button
                            fullwidth
                            label="Cadastrar"
                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                            spaceBetween
                            loading={requestCadastro.loading}
                            onClick={this.onCadastroClick}
                            disabledStyle={!formCadastro.isValid}
                        />
                    </DivSmart>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect(
    {
        formCadastro: form(state`cadastro.formCadastro`),

        requestCadastro: state`cadastro.requestCadastro`,
        signalCadastro: signal`cadastro.cadastro`,

        touchForm: signal`form.touchForm`,
        signalResetRequest: signal`form.resetRequest`,
        signalResetForm: signal`form.resetForm`,
        signalSetField: signal`form.setField`,

        signalSetFormValidationSocial: signal`cadastro.setFormValidationSocial`,
        signalLoginSocial: signal`login.loginSocial`,
    },
    CadastroPage
);
