import classnames from 'classnames';
import DivSmart from 'components/DivSmart';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './TextArea.module.scss';

const TextArea = ({ placeholder, error, value, onChange, onBlur, onKeyPress, maxLength, rows }) => {
    const propsInput = {
        className: styles.textarea,
        placeholder,

        onChange,
        onBlur,
        onKeyPress,
        maxLength,
        value,

        rows,
    };

    const total = !!maxLength ? maxLength - (value ? value.length : 0) : 0;

    return (
        <div className={classnames(styles.TextArea, { [styles.error]: !!error })}>
            <textarea {...propsInput}>{value}</textarea>

            <DivSmart row flex spaceBetween>
                {!!error && <div className={styles.errorText}>{error}</div>}
                {!error && <div>&nbsp;</div>}

                {!!maxLength && (
                    <div className={styles.counter}>
                        {total} Caracter{total <= 1 ? '' : 'es'}
                    </div>
                )}
            </DivSmart>
        </div>
    );
};

TextArea.defaultProps = {
    type: 'text',
    placeholder: '',
};

TextArea.propTypes = {
    placeholder: PropTypes.string,
    error: PropTypes.string,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    touched: PropTypes.bool,

    rows: PropTypes.number,
};

export default TextArea;
