import { form } from '@cerebral/forms';
import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import ComponentConnect from 'components/ComponentConnect';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import TextArea from 'components/TextArea';
import React from 'react';
import { trackEvent } from 'utils/GAUtil';

class Step3Descricao extends React.Component {
    static defaultProps = {
        title: 'PEDIDO',
        text: 'Descreva o seu pedido e contexto da situação:',
    };

    onNextClick = () => {
        trackEvent('precisamos', this.props.title.toLowerCase() + '_descricao', this.props.formAdd.ref_category);

        this.props.onNext();
    };

    render() {
        const { formAdd, title, text } = this.props;

        return (
            <React.Fragment>
                <BgPanel>
                    <div className={'subtitle-15'}>{title}</div>

                    <Spacer vertical={4} />

                    <div className={'title-26'}>{text}</div>

                    <Spacer vertical={2} />

                    <ComponentConnect path="pin.formAdd.description">
                        <TextArea maxLength={180} rows={8} placeholder="Lembre-se de não fornecer dados pessoais." />
                    </ComponentConnect>
                </BgPanel>

                <Spacer vertical={4} horizontal={8}>
                    <DivSmart flex row spaceBetween>
                        <ButtonText
                            iconLeft={"<i class='fas fa-chevron-left'></i>"}
                            label={'Retornar'}
                            onClick={this.props.onBack}
                        />

                        <Spacer horizontal={10} />

                        <Button
                            fullwidth
                            label="Avançar"
                            iconRight={"<i class='fas fa-chevron-right'></i>"}
                            spaceBetween
                            onClick={this.onNextClick}
                            disabled={!formAdd.description.isValid}
                        />
                    </DivSmart>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect({ formAdd: form(state`pin.formAdd`) }, Step3Descricao);
