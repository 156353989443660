import { httpGet, httpPost } from '@cerebral/http/operators';
import { set } from 'cerebral/operators';
import { props, state } from 'cerebral/tags';
import getLodash from 'lodash/get';
import { modal } from './modal/modal';

export function createFeedback(showSuccess = true, showError = true, successMessage = null, errorMessage = null) {
    return {
        success: showSuccess
            ? [
                  modal({ title: successMessage ? successMessage : props`message` }, [{ path: 'ok', label: 'OK' }]),
                  {
                      ok: [],
                  },
              ]
            : [],
        error: showError
            ? [
                  modal(
                      {
                          title: errorMessage ? errorMessage : props`message`,
                          theme: 'red',
                      },
                      [{ path: 'ok', label: 'OK' }]
                  ),
                  {
                      ok: [],
                  },
              ]
            : [],
    };
}

export function get(url, pathname, data, resetState = false) {
    return [
        set(state`${pathname}.url`, url),
        ({ state, props }) => startRequest(state, props, pathname, resetState),
        httpGet(url),
        {
            success: [({ state, props }) => httpSuccess(state, props, pathname)],
            error: [({ state, props }) => httpError(state, props, pathname)],
        },
        context => endRequest(context, pathname),
    ];
}

export function post(url, pathname, data, resetState = false) {
    return [
        set(state`${pathname}.url`, url),
        ({ state, props }) => startRequest(state, props, pathname, resetState),
        httpPost(url, data),
        {
            success: [({ state, props }) => httpSuccess(state, props, pathname)],
            error: [({ state, props }) => httpError(state, props, pathname)],
        },
        context => endRequest(context, pathname),
    ];
}

function endRequest(context, pathname) {
    const state = context.state;
    state.set(`${pathname}.loading`, false);
    state.set(`${pathname}.date`, new Date().getTime());

    if (context.path) {
        const error = state.get(`${pathname}.error`);
        return error ? context.path.error() : context.path.success();
    }
}

function startRequest(state, props, pathname, resetState) {
    state.set(`${pathname}.loading`, true);
    state.set(`${pathname}.pristine`, false);

    if (resetState) {
        state.set(`${pathname}.result`, null);
    }
}

function httpSuccess(state, props, pathname) {
    state.set(`${pathname}.error`, false);
    state.set(`${pathname}.result`, props.response.result);
    state.set(`${pathname}.status`, props.response.status);
    state.set(`${pathname}.success`, true);
    // props.message = getMessage(props.response.result);
    props.isSuccess = true;
    props.message = getMessage(props.response.result);
}

function httpError(state, props, pathname) {
    state.set(`${pathname}.error`, true);
    state.set(`${pathname}.result`, props.error.response.result);
    state.set(`${pathname}.status`, props.error.response.status || 404);
    state.set(`${pathname}.success`, false);

    props.message = getErrorMessage(props.error);
}

export function APIResponse(obj = {}) {
    obj.pristine = true;
    obj.loading = false; //true | false
    obj.status = null; //200 , 400 , 404 .. etc
    obj.result = obj.result ? obj.result : null; // {json}
    obj.error = false; //true | false
    obj.date = null; //
    obj.url = null; //
    return obj;
}

function getMessage({ data }) {
    if (data && data.message) {
        return data.message;
    } else {
        return '';
    }
}

function getErrorMessage(data) {
    const message = getLodash(data, 'response.result.error.message');
    if (message) {
        return message;
    }

    const type = data.type;
    if (type === 'timeout') {
        return 'Timeout';
    }

    return '';
}
