import { connect } from '@cerebral/react';
import { signal, state } from 'cerebral/tags';
import BgPanel from 'components/BgPanel';
import Button from 'components/Button';
import CardCategory from 'components/CardCategory';
import Loading from 'components/Loading';
import Spacer from 'components/Spacer';
import React from 'react';
import { Link } from 'react-router-dom';

class ListaAjudas extends React.Component {
    componentWillUnmount() {}

    componentDidMount() {
        this.props.signalListPin({ type: 'posso_ajudar' });
    }

    onRemoveClick = data => {
        //Type para reload
        this.props.signalRemovePin({
            id: data.id,
            type: 'posso_ajudar',
            modalMessage: 'Você tem certeza que deseja encerrar a ajuda?',
        });
    };

    render() {
        const { result, loading } = this.props.requestListPin;

        return (
            <React.Fragment>
                <BgPanel>
                    {loading && <Loading />}

                    {result &&
                        result.data &&
                        result.data.map(v => {
                            return (
                                <CardCategory
                                    pink
                                    key={v.id}
                                    data={v}
                                    onRemoveClick={this.onRemoveClick.bind(this, v)}
                                />
                            );
                        })}

                    {result && result.data && result.data.length === 0 && (
                        <div className="text-14">
                            Você ainda não ofereceu nenhuma ajuda. Clique no botão abaixo para postar o seu primeiro PIN
                        </div>
                    )}

                    <Spacer vertical={2} />
                </BgPanel>

                <Spacer horizontal={8} vertical={4}>
                    <Link to="/ajudar/adicionar-ajuda">
                        <Button label="Posso Ajudar" pink />
                    </Link>
                </Spacer>
            </React.Fragment>
        );
    }
}

export default connect(
    {
        signalListPin: signal`pin.listPin`,
        signalRemovePin: signal`pin.removePin`,
        requestListPin: state`pin.requestListPin`,
    },
    ListaAjudas
);
