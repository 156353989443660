import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import classnames from 'classnames';
import DivSmart from 'components/DivSmart';
import Spacer from 'components/Spacer';
import React from 'react';
import styles from './Status.module.scss';

class Status extends React.Component {
    render() {
        const { config } = this.props;

        if (!config || (!config.totalAjuda && !config.totalPedido)) {
            return null;
        }

        return (
            <DivSmart flex row spaceBetween alignCenter>
                <DivSmart flex row alignCenter>
                    <img src={require('./assets/pedidos.png')} alt="" className={styles.img} />

                    <Spacer horizontal={0.5} />

                    <div>
                        <div className={classnames('color-lightblue', styles.number)}>{config.totalPedido}</div>
                        <div className={styles.text}>Pedidos de Ajuda</div>
                    </div>
                </DivSmart>

                <Spacer horizontal={1} />

                <DivSmart flex row alignCenter>
                    <img src={require('./assets/ajudas.png')} alt="" className={styles.img} />

                    <Spacer horizontal={0.5} />

                    <div>
                        <div className={classnames('color-pink', styles.number)}>{config.totalAjuda}</div>
                        <div className={styles.text}>Pessoas Ajudando</div>
                    </div>
                </DivSmart>
            </DivSmart>
        );
    }
}

export default connect({ config: state`app.config` }, Status);
