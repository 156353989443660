import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './DivSmart.module.scss';

const DivSmart = ({
    justifyCenter,
    alignCenter,
    alignStart,
    spaceBetween,
    spaceAround,
    row,
    column,
    flex,
    flex1,
    children,
}) => {
    return (
        <div
            className={classnames({
                [styles.flex]: flex,
                [styles.flex1]: flex1,
                [styles.row]: row,
                [styles.column]: column,
                [styles.alignCenter]: alignCenter,
                [styles.alignStart]: alignStart,
                [styles.justifyCenter]: justifyCenter,
                [styles.spaceBetween]: spaceBetween,
                [styles.spaceAround]: spaceAround,
            })}
        >
            {children}
        </div>
    );
};

DivSmart.propTypes = {
    flex: PropTypes.bool,
    row: PropTypes.bool,
    alignCenter: PropTypes.bool,
    justifyCenter: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    spaceAround: PropTypes.bool,
};

export default DivSmart;
