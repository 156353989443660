import classnames from 'classnames';
import Modal from 'components/Modal/Modal';
import Spacer from 'components/Spacer';
import PropTypes from 'prop-types';
import * as React from 'react';
import styles from './ModalSimple.module.scss';

class ModalSimple extends React.Component {
    static defaultProps = {};

    static propTypes = {
        title: PropTypes.string,
        onClose: PropTypes.func,
        classNames: PropTypes.any,
        visible: PropTypes.bool,
    };

    render() {
        const { visible, onClose, className, title } = this.props;

        return (
            <Modal className={className} visible={visible} onClose={onClose}>
                <div className={classnames('fas', 'fa-times', styles.btnClose)} onClick={onClose}></div>

                <div className={classnames('modal-content', styles.content)}>
                    {!!title && (
                        <React.Fragment>
                            <div className={'title-22'}>{title}</div>
                            <Spacer vertical={2} />
                        </React.Fragment>
                    )}

                    {this.props.children}
                </div>
            </Modal>
        );
    }
}

export default ModalSimple;
