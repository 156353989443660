import { connect } from '@cerebral/react';
import { state } from 'cerebral/tags';
import classnames from 'classnames';
import DivSmart from 'components/DivSmart';
import React from 'react';
import styles from './CardConfirm.module.scss';

const CardConfirm = ({ id, text, date, categories }) => {
    let category = categories.filter((v) => v.id * 1 === id * 1);

    if (category.length === 0) return 'err category';

    category = category[0];

    return (
        <div className={classnames(styles.root)}>
            <DivSmart flex row alignCenter>
                <div className={styles.image}>
                    <img src={category.image} alt="" />
                </div>

                <div>
                    <div className={styles.title}>{category.name}</div>
                    <div className={styles.subtitle}>{date}</div>
                </div>
            </DivSmart>

            {category.apoio && category.apoio_img && (
                <div className={styles.apoio}>
                    <span>Apoio</span>
                    <img src={category.apoio_img} alt={category.apoio} />
                </div>
            )}

            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default connect({ categories: state`app.config.categories` }, CardConfirm);
