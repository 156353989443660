import classnames from 'classnames';
import Button from 'components/Button';
import CardShare from 'components/CardShare';
import DivSmart from 'components/DivSmart';
import Config from 'Config';
import Controller from 'modules/Controller';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobile, openModalApi } from 'utils/AppUtils';
import { go } from 'utils/RouterUtils';
import styles from './InfoWindow.module.scss';
import { trackEvent } from 'utils/GAUtil';

class InfoWindow extends React.PureComponent {
    state = {
        report: false,
        share: false,
    };

    static propTypes = {
        onClose: PropTypes.func,
        authUser: PropTypes.any,
    };

    myRef = React.createRef();

    componentDidMount() {
        if (!isMobile()) {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (!isMobile()) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.myRef.current.contains(e.target)) {
            setTimeout(() => {
                this.props.onClose();
            }, 100);
        }
    };

    onToggleReport = () => {
        this.setState({ report: !this.state.report, share: false });
    };

    onToggleShare = () => {
        this.setState({ share: !this.state.share, report: false });
    };

    onReportPedidoClick = () => {
        Controller.getSignal('pin.reportPin')({ id: this.props.data.id });
    };

    isOwner = () => {
        const { authUser } = this.props;
        if (authUser && authUser.id * 1 === this.props.data.ref_user * 1) {
            return true;
        }

        return false;
    };

    onQueroAjudarClick = () => {
        if (this.isOwner()) {
            return;
        }

        const { id, ref_category, type, apoio, apoio_link_mapa, apoio_target_mapa } = this.props.data;

        trackEvent('precisamos', 'clique_pin_quero_ajudar_aceitar_ajuda_id', id);

        trackEvent('precisamos', 'clique_pin_quero_ajudar_aceitar_ajuda_categoria', ref_category);

        if (apoio && apoio_link_mapa) {
            if (apoio_target_mapa === '_modal') {
                openModalApi(apoio_link_mapa);
            } else {
                go(apoio_link_mapa);
            }
        } else {
            if (type === 'pedir_ajuda') {
                go(`/quero-ajudar/${id}`);
            } else {
                go(`/aceitar-ajudar/${id}`);
            }
        }
    };

    render() {
        const { onClose } = this.props;
        const { id, name, dt_created, description, image, image_white, type, apoio, apoio_img, ref_category } =
            this.props.data;

        const isSOSRS = ref_category == 25;

        const labelBtn = type === 'pedir_ajuda' ? 'Quero Ajudar' : 'Pedir Ajuda';
        const propsBtn = type === 'pedir_ajuda' ? { lightblueWhite: true } : { pink: true };

        return (
            <div
                ref={this.myRef}
                onClick={this.handleClickInside}
                className={classnames(styles.infoWindow, {
                    [styles.pedidos]: type === 'pedir_ajuda',
                    [styles.ajudar]: type === 'posso_ajudar',
                })}
            >
                {isMobile() && (
                    <div className={styles.btnClose} onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </div>
                )}

                <div className={styles.container}>
                    <div className={styles.arrow}>
                        <img src={require('./assets/arrow.png')} alt="" />
                    </div>

                    <DivSmart flex row spaceBetween>
                        <div className={styles.image}>
                            <img src={image_white} className={styles.image} alt="" />
                        </div>

                        <div className={styles.infoCategory}>
                            <div className={styles.category}>{name}</div>
                            <div className={styles.date}>{moment.unix(dt_created).format('DD/MM/YY')}</div>
                        </div>

                        <div className={styles.btnReport} onClick={this.onToggleReport}>
                            <img src={require('./assets/warning.png')} alt="" />
                        </div>
                    </DivSmart>

                    {apoio && apoio_img && (
                        <div className={styles.apoio}>
                            <span>Apoio</span>
                            <img src={apoio_img} alt={apoio} />
                        </div>
                    )}

                    <div className={styles.description}>{description}</div>

                    <DivSmart flex1 flex />

                    <DivSmart flex row spaceBetween alignCenter>
                        <div className={styles.btnShare} onClick={this.onToggleShare}>
                            <img src={require('./assets/share.png')} alt="" />
                            <div>Compartilhar</div>
                        </div>

                        {!!isSOSRS && (
                            <Button
                                comp="a"
                                className={styles.btnHelp}
                                fullwidth
                                btnMedium
                                label={labelBtn}
                                {...propsBtn}
                                target="_blank"
                                href={"https://sos-rs.com/?search="+description}
                            />
                        )}

                        {!isSOSRS && (
                            <Button
                                className={styles.btnHelp}
                                fullwidth
                                btnMedium
                                label={labelBtn}
                                {...propsBtn}
                                onClick={this.onQueroAjudarClick}
                                disabled={this.isOwner()}
                            />
                        )}
                    </DivSmart>
                </div>

                {this.state.report && (
                    <div className={styles.blockReport}>
                        <Button red label="Reportar este pedido" onClick={this.onReportPedidoClick}></Button>
                    </div>
                )}

                {this.state.share && (
                    <div className={styles.blockShare}>
                        <CardShare
                            link={`${Config.URL}share/pin/${id}`}
                            thin
                            text={'Precisamos - ' + description}
                        ></CardShare>
                    </div>
                )}
            </div>
        );
    }
}

export default InfoWindow;
